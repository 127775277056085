/* eslint-disable no-template-curly-in-string */

export default {
  loaderInfo: "Cargando contenido",
  characters: "caracteres",
  maximum: "Máximo",
  of: "de",
  showcase: {
    investToday: "¿En qué quieres invertir hoy?",
    ourShowcase: "Nuestra vitrina",
    loading: "Cargando...",
    viewDetail: "Ver detalle",
    invest: "Invertir",
    createAccount: "Crear cuenta",
    seeShowcase: "Ver vitrina",
    seeVideo: "Ver video",
    ready: "Estoy listo",
    SEOInfo: {
      title: "¿Quieres invertir? Crowdfunding | a2censo",
      description:
        "Encuentra y financia una empresa o pyme en Colombia e invierte en a2censo con el respaldo de la bolsa de valores de colombia. Ingresa ahora"
    },
    toInvest: "Campañas para invertir",
    active: "Campañas exitosas",
    closed: "Campañas cerradas"
  },
  campaign: {
    comingSoon: "Próximamente",
    risks: "Riesgos",
    team: "Equipo",
    partners: "Socios",
    knowUs: "Conoce nuestra campaña",
    ourCompany: "Nuestra empresa",
    simulate: "Simular",
    classification: "Clasificación",
    paymentInterestAndCapital: "Pago intereses y capital",
    quarterly: "Trimestral",
    toExpiration: "Al vencimiento",
    annual: "Anual",
    monthly: "Mensual",
    biannual: "Semestral",
    collectedAmount: "Monto recaudado",
    amount: "Monto",
    invest_from: "Invierte a partir del",
    collected: "Recaudado",
    investors: "Inversionistas",
    currency: "COP",
    goal: "Meta",
    goalAccomplished: "Meta cumplida",
    daysToClose: "días para cerrar",
    releaseDate: {
      firstText: "Conócela desde ya e",
      secondText: "invierte a partir del:"
    },
    investingCampaigns: {
      campaigns: "Campañas",
      toInvesting: "para invertir",
      noData: "No se encontraron campañas que coincidan con tu búsqueda",
      noDataStart:
        "¡Prepárate! Próximamente tendremos nuevas campañas para hacer crecer tu dinero.",
      pager: {
        showLess: "Cargar menos",
        loadMore: "Cargar más",
        separator: "de",
        item: "Campañas"
      }
    },
    totalCampaigns: "Total de campañas",
    description: {
      title: "Conoce nuestra campaña",
      project: "¿Qué haremos?",
      whyTrust: "¿Por qué confiar en nuestro proyecto?",
      how: "¿Cómo lo haremos realidad?",
      risks: "Algunos riesgos",
      interestPayment: "Pago intereses",
      interestPaymentValues: {
        1: "Mensual",
        3: "Trimestral",
        6: "Semestral",
        12: "Anual"
      },

      interestPaymentTerm: "Al vencimiento",
      gracePeriodPayment: "Periodo de gracia de intereses",
      gracePeriodMonthly: "1 Mes",
      capitalPayment: "Pago capital",
      paymentFrequency: "Frecuencia de pago",
      capitalPaymentValues: {
        0: "Al vencimiento",
        1: "Mensual",
        3: "Trimestral",
        6: "Semestral",
        12: "Anual"
      },
      capitalPaymentTerm: "Al vencimiento",
      gracePeriodCapital: "Periodo de gracia de capital",
      gracePeriodCapitalNoApply: "No aplica",
      gracePeriodCapitalMonth: "Mes",
      gracePeriodCapitalMonths: "Meses",
      interestPaymentInfo:
        "Se refiere a la frecuencia con la que recibirás el pago de los intereses generados por la campaña.",
      gracePeriodPaymentInfo:
        "Este periodo se refiere al tiempo que la empresa estará libre del pago de intereses a sus inversionistas, una vez culminado dicho periodo estos intereses serán pagados por la empresa según corresponda.",
      capitalPaymentInfo:
        "Se refiere a la frecuencia con la que recibirás el pago del capital invertido en la campaña.",
      gracePeriodCapitalInfo:
        "Este periodo se refiere al tiempo que la empresa no realizará pagos de capital. Una vez culminado dicho periodo este capital será pagado por la empresa según corresponda"
    },
    info: {
      title: "Nuestra empresa",
      generalInfo: "Información general",
      team: "Equipo y socios",
      providers: "Proveedores",
      customers: "Clientes",
      value: "Valor agregado",
      futureProjects: "Proyección a futuro",
      financialData: "Datos financieros"
    },
    return: "Volver",
    noDataSimulator:
      "¡Prepárate! Próximamente tendremos nuevas campañas para simular.",
    countDown: {
      itStartsAt: "Inicia en",
      hours: "Hrs",
      days: "Días",
      minutes: "Mins"
    }
  },
  campaignDetail: {
    shareThisCampaign: "Comparte esta campaña:",
    shareThisCampaignMobile: "Comparte:",
    shareDebt:
      "¡Esta campaña está genial!, creo que te gustaría invertir en ella.",
    shareShares: "Estoy pensando en ser accionista de esta empresa, ¿Te unes?",
    shareConvertibleNote: "Invertimos juntos en esta campaña. ¡Vamos!",
    conditions: "Condiciones",
    whatWillWeDo: "¿Qué haremos?",
    whyTrust: "¿Por qué confiar en nuestro proyecto?",
    howWillWeDoIt: "¿Cómo lo haremos realidad?",
    presentationInfo: "Ver información"
  },
  campaignDetailInfoBanner: {
    question: "¿Quieres conocer más?",
    text: "Inicia sesión y toma decisiones basadas en la información."
  },
  campaignConditions: {
    conditions: "Condiciones",
    sharesQuantity: "Acciones en la ronda",
    sharesType: "Tipo de acción",
    sharesForPackage: "Número de acciones por paquete",
    minInversionPackage: "Paquete mínimo de inversión",
    sharesPrice: "Precio de la acción",
    patrimonialPercentageEmited: "Porcentaje del patrimonio",
    companyValue: "Valoración de la empresa",
    investmentRoundType: "Tipo de ronda de inversión",
    classification: "Clasificación",
    sector: "Sector",
    tooltipSharesQuantity:
      "Es la cantidad de acciones que la empresa pone a la venta en a2censo, no pueden ser compradas por un solo inversionista",
    tooltipSharesForPackage:
      "Es la cantidad de acciones que tiene cada uno de los paquetes mínimos de inversión",
    tooltipMinInversionPackage:
      "Corresponde al grupo de acciones más pequeño que puedes comprar",
    tooltipPatrimonialPercentageEmited:
      "Indica el porcentaje del valor de la empresa que se pone a la venta",
    tooltipCompanyValue:
      "Es el cálculo por el cual se establece el valor de la compañía. Este valor es proporcionado por la empresa, bvc no tiene injerencia ni se hace responsable por este.",
    fixedRate: "Tasa fija",
    term: "Plazo",
    interestManagement: "Manejo de intereses",
    capitalManagement: "Manejo de capital",
    discountRate: "Tasa de descuento",
    cap: "CAP",
    tooltipFixedRate:
      "Es el interés calculado en el plazo de un año, teniendo en cuenta la frecuencia de pago de tu inversión",
    tooltipTerm: "Es el tiempo de vida de la nota convertible",
    tooltipInterestManagement:
      "En caso de hacerse la conversión, serán sumados al capital convertible en acciones. De no hacerse la conversión estos serán pagados por la empresa",
    tooltipCapitalManagement:
      "En caso de hacerse la conversión, el préstamo que le hiciste a la empresa se convertirá en acciones. De no hacerse la conversión este será pagado por la empresa",
    tooltipCap:
      "CAP: Es la valoración máxima a la cual se convierte la inversión en acciones%brTasa de descuento: Ante un evento de conversión, la tasa de descuento le provee a los inversionistas acciones a un precio inferior con relación al precio por acción de nuevos inversionistas.%brEn caso de que aplique una tasa de descuento, el precio menor por acción después de aplicado el descuento y el CAP, es el que se tendrá en cuenta al momento en el que tu inversión se convierta en acciones."
  },
  financedYourself: {
    how: {
      title: {
        normal: "¿Cómo te",
        bold: " financias en a2censo?"
      },
      callToAction: "¡Finánciate ahora!",
      step1: {
        title: {
          normal: "",
          bold: "Regístrate"
        },
        description:
          "Con unos datos básicos de tu empresa podrás crear tu cuenta y nuestro equipo comercial te contactará para acompañarte en el proceso"
      },
      step2: {
        title: {
          normal: "Crea",
          bold: " tu campaña"
        },
        description:
          "Compártenos la información de tu empresa y del proyecto que quieres financiar en a2censo, y nosotros lo evaluaremos."
      },
      step3: {
        title: {
          normal: "Conéctate con",
          bold: " tus inversionistas"
        },
        description:
          "Publicaremos tu campaña en a2censo para que todos te conozcan e inviertan en tu proyecto."
      },
      step4: {
        title: {
          normal: "Haz realidad",
          bold: " tu proyecto"
        },
        description:
          "Recibe tu dinero, ejecuta tu proyecto y salda tu deuda de acuerdo a las condiciones pactadas inicialmente"
      }
    },
    commitments: {
      title: {
        normal: "¿A qué",
        bold: " te comprometes?"
      },
      callToAction: "¡Finánciate ahora!",
      commitment1: {
        normal: "Suministrar la información requerida de la empresa",
        bold: " para conocerla en detalle."
      },
      commitment2: {
        normal:
          "Compartir la descripción completa del proyecto que se quiere financiar,",
        bold:
          " junto con la información financiera, legal y de los socios de la empresa."
      },
      commitment3: {
        normal: "Leer, aceptar y suscribir",
        bold: "los términos y condiciones del proyecto."
      },
      commitment4: {
        normal: "Mantener actualizada la información sobre la",
        bold: "evolución de la empresa y el proyecto financiado."
      }
    },
    FNGInformation: {
      title: "El FNG respalda tu financiación.",
      description:
        "Contarás con el apoyo del Fondo Nacional de Garantías, para respaldar un porcentaje de tu deuda, y de está manera aumentar la confianza de tus inversionistas."
    }
  },
  a2censo: {
    allies: "Aliados",
    supportFor: "Apoyo",
    aboutUs: {
      knowUs: {
        heading: {
          normal: "Somos",
          bold: " a2censo"
        },
        paragraph:
          "El ecosistema de inversión y financiación de La Bolsa de Valores de Colombia, en el que toda una comunidad puede cumplir sus metas, haciendo crecer sus ahorros mientras financian los proyectos de empresas colombianas."
      },
      ourAllies: {
        heading: {
          normal: "¡Somos un producto de la",
          bold: " Bolsa de Valores de Colombia!"
        },
        paragraph:
          "Estamos creando la nueva generación de empresarios e inversionistas del país."
      }
    },
    knowUs: {
      title: "Conócenos",
      firstRow: {
        title: "Somos diferentes",
        description:
          "¡Aquí, tu tomas las decisiones! Te conectamos con nuevas oportunidades de crecimiento, dándote la libertad de elegir la que más se acomode a tus expectativas, siendo 100% transparentes en todo el proceso."
      },
      secondRow: {
        title: "Propuesta de valor",
        description:
          "Creemos que la colaboración hace la diferencia, desde el mercado de valores logramos generar relaciones gana - gana, conectando los deseos de crecimiento del sector empresarial colombiano con los sueños de las personas para el desarrollo del país."
      }
    },
    ourAllies: { title: "Nuestros aliados" }
  },
  financialInfo: {
    rate: "Tasa: fija",
    warranty: "Garantía",
    term: "Plazo",
    classification: "Clasificación",
    interest: "Pago de intereses",
    sector: "Sector",
    months: "meses",
    month: "mes",
    gracePeriodPayment: "Periodo de gracia de intereses",
    gracePeriodPaymentCapital: "Periodo de gracia de capital",
    capital: "Pago capital",
    tooltipFng:
      "Es el porcentaje de tu inversión que está respaldado por el FNG, y cubre tu capital en caso de eventualidades por parte de las empresas.",
    tooltipInterest:
      "Se refiere a la frecuencia con la que recibirás el pago de los intereses generados por la campaña.",
    tooltipCapital:
      "Se refiere a la frecuencia con la que recibirás el pago del capital invertido en la campaña.",
    tooltipGracePeriodPayment:
      "Este periodo se refiere al tiempo que la empresa estará libre del pago de intereses a sus inversionistas, una vez culminado dicho periodo estos intereses serán pagados por la empresa según corresponda.",
    tooltipGracePeriodPaymentCapital:
      "Este periodo se refiere al tiempo que la empresa no realizará pagos de capital. Una vez culminado dicho periodo este capital será pagado por la empresa según corresponda.",
    tooltipWarranty:
      "Es el porcentaje de tu inversión que está respaldado por el FNG, y cubre tu capital en caso de eventualidades por parte de las empresas.",
    risk: {
      visionary: {
        title: "Visionario",
        statement:
          "Estos Proyectos Productivos presentan simultáneamente las siguientes tres características:",
        message:
          "La formulación del proyecto es adecuada:\n se presenta en términos claros y abarca todos los\n aspectos relevantes para el desarrollo del proyecto. %br Cuenta con un componente de innovación. %br Aporta de manera positiva (social y/o medioambiental) al entorno en el cual se desarrolla."
      },
      innovative: {
        title: "Innovador",
        statement:
          "Estos Proyectos Productivos presentan simultáneamente las siguientes dos características:",
        message:
          "La formulación del proyecto es adecuada; se presenta en términos claros y abarca todos los aspectos relevantes para el desarrollo del proyecto;%br Cuenta con un componente de innovación."
      },
      sustainable: {
        title: "Sostenible",
        statement:
          "Estos Proyectos Productivos presentan simultáneamente las siguientes dos características:",
        message:
          "La formulación del proyecto es adecuada; se presenta en términos claros y abarca todos los aspectos relevantes para el desarrollo del proyecto;%br Aporta de manera positiva (social y/o medioambiental) al entorno en el cual se desarrolla."
      },
      classic: {
        title: "Clásico",
        statement:
          "Estos Proyectos Productivos presentan simultáneamente las siguientes dos características:",
        message:
          "La formulación del proyecto es adecuada; se presenta en términos claros y abarca todos los aspectos relevantes para el desarrollo del proyecto.%br  Está enfocado en aspectos de desarrollo y/o crecimiento empresarial."
      }
    }
  },
  anonymous: {
    connectWithA2censo: "Conéctate con a2censo",
    knowAll: "De esta forma podrás conocer todas las",
    campaingAndContent: "campañas y contenido que tenemos para ti."
  },
  welcome: "Hola, Bienvenido a",
  grability: "Grability",
  not_found: "Oops!, Seguro que quieres ingresar aquí?",
  terms: {
    title: "Términos y condiciones",
    title2:
      "Hemos actualizado nuestros términos y condiciones. Acéptalos para poder continuar.",
    text: `
TÉRMINOS Y CONDICIONES DE LA PLATAFORMA DE FINANCIACIÓN 
COLABORATIVA – a2censo


1. Actividad de Financiación Colaborativa - Crowdfunding

La Bolsa de Valores de Colombia S.A. (en adelante bvc) administra una plataforma que realiza la actividad de financiación colaborativa prevista en el el artículo 2.41.1.1.1 y siguientes del Decreto 2555 de 2010. Tal Plataforma de Financiación Colaborativa se denomina a2censo (en adelante a2censo o la Plataforma). 

La administración de la actividad de financiación colaborativa realizada por a2censo se desarrolla básicamente a través de una infraestructura tecnológica, que puede incluir interfaces, plataformas, páginas de internet u otro medio de comunicación electrónica o digital, a través del cual se pone en contacto un número plural de aportantes con receptores que solicitan financiación en nombre propio para destinarlo a un proyecto productivo. 

La financiación colaborativa se materializa a través de la adquisición de valores de financiación colaborativa y es realizada directamente por los Aportantes en favor de los Receptores. Los valores de financiación colaborativa emitidos y adquiridos a través de a2censo se consideran valores en los términos del artículo 2 de la Ley 964 de 2005, en concordancia con lo dispuesto en el artículo 2.41.5.1.1 del Decreto 2555 de 2010 y demás normas que lo modifiquen, adicionen o deroguen. 

2. Alcance de la Responsabilidad de bvc y/o a2censo 

Con la aceptación de los presentes Términos y Condiciones los Aportantes y los Receptores aceptan y entienden que:

2.1 La aceptación y/o publicación de una emisión o de un Proyecto Productivo en la Plataforma no implicará calificación ni responsabilidad alguna por parte de bvc acerca de las personas jurídicas, ni de las emisiones, ni de los Proyectos Productivos, ni sobre el precio, la bondad o negociabilidad del valor, o de la respectiva emisión, ni sobre la solvencia del Receptor, ni de solidez o respaldo de los valores que se emitan en la Plataforma;
2.2 Las obligaciones de bvc y a2censo son de medio y no de resultado, por ello ninguna operación, actividad o publicación de bvc y/o de a2censo puede ser considerada como una obligación o compromiso de resultado respecto de las inversiones realizadas;
2.3 Las actividades de clasificación y ninguna otra puede entenderse en ningún caso como  una  calificación de los riesgos asociados a los mismos, ni podrá considerarse como la emisión de una opinión o la promesa o el aseguramiento de obtención de rentabilidades para los Aportantes. bvc provee la información que los Aportantes pueden utilizar para la toma de decisiones de inversión;
2.4 Bvc no otorga garantías, avales, ni ningún tipo de seguridades respecto de los valores que sean emitidos a través de la Plataforma;
2.5 bvc es administrador de a2censo y no responde por la suspensión o interrupción de los servicios de a2censo, ni por deficiencias mecánicas, electrónicas, de software que se observen en la prestación de los mismos, ni por cualquier otro hecho que escape razonablemente al control de bvc. No obstante, bvc desplegará sus mejores esfuerzos para mantener o restablecer el funcionamiento de la Plataforma;
2.6 En relación con su actividad como administrador de a2censo, el cumplimiento de normas aplicables y el funcionamiento de la Plataforma, bvc sólo será responsable de los daños causados por su culpa grave o dolo.

3. Conocimiento y Aceptación del Reglamento, Circular e Instructivos Operativos de a2censo 

a2censo cuenta con un Reglamento de la Plataforma de Financiación Colaborativa – a2censo (en adelante Reglamento), una Circular de la Plataforma de Financiación Colaborativa – a2censo (en adelante Circular) y unos Instructivos Operativos, que se encuentran publicados en www.a2censo.com. Tales documentos desarrollan lo previsto en el Libro 41 de la Parte 2 del Decreto 2555 de 2010 e incluyen las condiciones de uso, derechos, obligaciones y responsabilidades de los Aportantes, Receptores y bvc. 

Con la aceptación de los presentes Términos y Condiciones se entiende que los Aportantes y los Receptores conocen y aceptan el Reglamento, la Circular y los Instructivos Operativos de a2censo. En consecuencia, en ningún momento servirá como excusa o defensa la ignorancia o desconocimiento de dicho Reglamento, Circular e Instructivos Operativos y por lo tanto los mismos obligan en los términos en ellos previstos.

4. Riesgos y Responsabilidades 

Adicionalmente, con la aceptación de los presentes Términos y Condiciones los Aportantes y los Receptores aceptan y entienden que:

4.1 La inversión realizada por los Aportantes en valores de financiación colaborativa es de alto riesgo y que pueden incluso perder el capital invertido;
4.2 Si el Receptor incumple con sus obligaciones la probabilidad de recuperación del capital es remota y por ende es alta la probabilidad de pérdida del capital en esos eventos;
4.3 Las garantías que respaldan las emisiones en ningún evento respaldan la totalidad de los montos invertidos, por lo anterior, exoneran a bvc de cualquier reclamación o perjuicio por el incumplimiento de las obligaciones del Receptor y por la no recuperación de recursos en ejecución de las garantías;
4.4 Según lo previsto en las normas vigentes, bvc realizará una clasificación de los Proyectos Productivos. La clasificación de los Proyectos Productivos, en ningún caso implica la calificación de los riesgos asociados a los mismos, ni podrá considerarse como la emisión de una opinión o la promesa o el aseguramiento de obtención de rentabilidades para los Aportantes. bvc provee información suministrada por los Receptores que los Aportantes pueden utilizar para la toma de decisiones de inversión;
4.5 bvc en ningún momento realiza calificaciones de ningún tipo de riesgos y tampoco hace recomendaciones de inversión. bvc provee la información que los Aportantes pueden utilizar para la toma de decisiones de inversión;
4.6 El Receptor es el único responsable por la integridad, veracidad, completitud y actualización de toda la información que deba suministrar a la Plataforma y en particular de aquella relacionada con el Proyecto Productivo, revelada a los Aportantes y al público en general;
4.7 bvc no será responsable por retraso, incumplimiento o demoras en los pagos por parte de los Receptores;
4.8 Con la presentación de cada Demanda de Inversión, los Aportantes aceptan que podrán ser adjudicados en un valor inferior al incluido en la Demanda de Inversión, según lo previsto en las reglas de Adjudicación y el prorrateo, incorporadas en el Reglamento y en la Circular.
4.9 La Plataforma es el mecanismo por medio del cual los Receptores realizan las emisiones. En consecuencia, la realización de estas emisiones en ningún caso implicará la calificación del Receptor por parte de la bvc, ni responsabilidad alguna de bvc acerca del Receptor, ni sobre la bondad de la respectiva emisión;
4.10 Los Aportantes asumen todos los riesgos inherentes a la realización de una inversión a través de una emisión en la Plataforma, y que la emisión y administración de los recursos producto de la misma son de competencia y responsabilidad exclusiva de los Receptores.
 
5. Manejo de usuarios, claves e información

Además de lo ya indicado en el presente documento, con la aceptación de los presentes Términos y Condiciones los Aportantes y los Receptores aceptan y entienden que: 

5.1 El acceso y uso de las funciones protegidas por contraseña de a2censo está restringido únicamente a los Aportantes o Receptores que creen una cuenta y se vinculen a la Plataforma. Para tal fin se debe cumplir a cabalidad con el proceso de creación de cuenta y vinculación en la Plataforma, que puede conllevar el registro con con una contraseña de al menos 8 caracteres con símbolos, números, mayúsculas y minúsculas;
5.2 Proporcionan datos ciertos, completos, actualizados y exactos, según se les solicite en el proceso de creación de cuenta, vinculación a la Plataforma y demás solicitudes de información por parte de a2censo;
5.3 Son responsables de mantener al día y actualizar cualquier dato de registro y otra información que proporcionen a a2censo;
5.4 Son responsables de mantener la seguridad de su usuario y contraseña, así como de cualquier actividad que ocurra bajo su cuenta;
5.5 Notificarán inmediatamente a bvc por los mecanismos institucionales establecidos de cualquier acceso o uso no autorizado de su cuenta o de sus credenciales de acceso;
5.6 Entienden que cualquier persona con su contraseña podrá acceder a su cuenta y a cualquier dato de registro, incluyendo, sin limitación, el acceso a sus módulos y funciones accesibles a través de su cuenta;
5.7 bvc no será responsable ante los Aportantes o Receptores por cualquier pérdida económica en la que puedan incurrir como resultado de que una persona diferente a usted utilice su usuario y/o su contraseña, con o sin su conocimiento;
5.8 Únicamente podrán hacer uso del usuario y clave que le fueron asignados para ingresar a la plataforma a2censo.

6. Autorizaciones y facultades otorgadas por los Aportantes a bvc

Con la aceptación de los presentes Términos y Condiciones los Aportantes facultan de manera irrevocable a bvc para lo siguiente:

6.1 Para que realice la custodia y administración de los Valores de Financiación Colaborativa que conforman su portafolio en el Depósito Centralizado de Valores de Colombia Deceval S.A., facultando a bvc para que:
6.1.1 Solicite a Deceval la habilitación de una subcuenta de depósito a nombre del Aportante, con el objeto de que se registren y contabilicen allí sus Valores de Financiación Colaborativa; 
6.1.2 Endose en administración los Valores de Financiación Colaborativa de  propiedad del Aportante y todos los que le sean transferidos en el futuro a través del depósito, en caso de que no lo haga directamente el Aportante;
6.1.3 Realice las operaciones que se puedan derivar como consecuencia del endoso en administración; y 
6.1.4 Transfiera, como consecuencia de las operaciones celebradas, Valores de Financiación Colaborativa a o del portafolio del Aportante.
6.2 Para realizar directamente o a través de terceros todos los trámites necesarios para el cobro del capital e intereses frente al Receptor o para realizar la negociación y venta de la cartera y adelantar gestiones judiciales o extra judiciales para el cobro de la cartera, incluso para enajenar por cuenta de los Aportantes dicha cartera a terceros, para lo cual también está facultada bvc para determinar el precio de dicha enajenación. 
6.3 Para adelantar todos los trámites necesarios para gestionar el cobro de las garantías, incluyendo las transferencias de dominio, en aquellos casos que se realice el pago de las mismas.

7. Declaración de origen de los fondos y actividades legales

Con la aceptación de los presentes Términos y Condiciones los Aportantes y los Receptores realizan las siguientes declaraciones:

7.1 Que los recursos que transfieren a a2censo provienen de actividades lícitas, de conformidad con la normativa colombiana.
7.2 Que no admitiran que terceros efectúen depósitos o transferencias a nombre suyo, con fondos provenientes de las actividades ilícitas contempladas en el Código Penal Colombiano o en cualquier norma que lo modifique o adicione; ni efectuarán transacciones destinadas a tales actividades o a favor de personas relacionadas con las mismas.
7.3 Que autorizan a resolver cualquier acuerdo, negocio o contrato celebrado en a2censo, en caso de infracción de cualquiera de los numerales de la Declaración de origen de fondos y actividades legales eximiendo a bvc de toda responsabilidad que se derive por información errónea, falsa o inexacta que hubieren proporcionado a la Plataforma, o por la violación de las declaraciones acá realizadas.

8. Mecanismos de Resolución de Controversias 

Con la aceptación de los presentes Términos y Condiciones los Aportantes y los Receptores aceptan los mecanismos de resolución de controversias establecidos en el Reglamento. 

`,
    acceptButton: "Aceptar",
    continue: "Continuar",
    thanks:
      "Gracias por aceptar los nuevos términos y condiciones. Ya estás listo para invertir en a2censo."
  },
  politics: {
    title: "Políticas",
    title2:
      "Hemos actualizado nuestra política de tratamiento de datos personales. Acéptala para poder continuar.",
    text: `
 Autorización tratamiento de datos personales 


 Con la aceptación de esta autorización, manifiesto que he sido informado por bvc y sus empresas filiales y asociadas (en adelante las “Compañías”) que: 

 1. Las Compañías actuarán como Responsables del Tratamiento de datos personales de los cuales soy titular y que, conjunta o separadamente podrán recolectar, usar, transferir, transmitir y tratar mis datos personales conforme la Política de Tratamiento de Datos Personales de las Compañías disponible en www.bvc.com.co 

 2. Es de carácter facultativo responder preguntas que versen sobre Datos Sensibles o sobre menores de edad. 

 3. El titular del dato sensible no está obligado autorizar su tratamiento por parte de las Compañías. 

 4. Mis derechos como titular de los datos son los previstos en la Constitución y la ley, especialmente el derecho a conocer, actualizar, rectificar y suprimir mi información personal, así como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales y datos sensibles. 

 5. Los derechos pueden ser ejercidos a través de los canales gratuitos dispuestos por las Compañías y observando la Política de Tratamiento de Datos Personales de las Compañías. 

 6. Para cualquier inquietud o información adicional relacionada con el tratamiento de datos personales, puedo contactarme al correo electrónico datospersonales@bvc.com.co o al teléfono 6068666. 

 7. Las Compañías garantizan la confidencialidad, libertad, seguridad, veracidad, transparencia, acceso y circulación restringida de mis datos y se reservan el derecho de modificar su Política de Tratamiento de Datos Personales en cualquier momento. Cualquier cambio será informado y publicado oportunamente en la página web o a través de los medios que disponga para tal fin. 

Así mismo, de conformidad con lo dispuesto en la ley 1266 de 2008, manifiesto que autorizó a las Compañías o a cualquier otro operador y/o fuente de información legalmente establecido a consultar, en cualquier tiempo, en DataCrédito o en cualquier otra base de datos manejada por un operador y/o fuente de información financiera y crediticia, toda la información relevante para conocer el desempeño financiero del Titular de la Información, la viabilidad para entablar o mantener una relación contractual y/o comercial, o para cualquier otra finalidad, incluyendo sin limitarse la realización de campañas de mercadeo, ofrecimiento de productos y publicidad en general, todo lo anterior frente al nacimiento, ejecución, modificación, liquidación y/o extinción de las obligaciones que se deriven por la utilización de la plataforma a2censo, deberes legales de contenido patrimonial, datos de ubicación y contacto (número de teléfono fijo, número de teléfono celular, dirección del domicilio, dirección laboral y correo electrónico) y lo atinente a las relaciones comerciales, financieras y en general socioeconómicas que haya entregado o que consten en registros públicos, bases de datos públicas o documentos públicos.

Autorización tratamiento de datos personales

La autorización anterior no impedirá al Titular de la Información a ejercer el derecho a corroborar en cualquier tiempo en bvc, en DataCrédito o en la central de información de riesgo a la cual se hayan suministrado los datos, que la información suministrada es veraz, completa, exacta y actualizada, y en caso de que no lo sea, a que se deje constancia de su desacuerdo, a exigir la rectificación y a ser informado sobre las correcciones efectuadas.

Teniendo en cuenta lo anterior, yo autorizo de manera voluntaria, previa, explícita, informada e inequívoca a las Compañías para tratar mis datos personales, mis “datos sensibles” e información financiera de acuerdo con la Política de Tratamiento de Datos Personales de las Compañías y para los fines relacionados con su objeto social y en especial para fines legales, contractuales, comerciales, de almacenamiento, uso, autenticación de identidad, mecanismo de prueba de vida, encuestas digitales, investigaciones de mercado, envío de comunicaciones, envío de cursos educativos ofrecidos por las Compañías, realizar comunicaciones oficiales, entrega de información relevante, realizar notificaciones de la cuenta, entrega de piezas y contenidos publicitarios, dar soporte a PQRS, solucionar inconsistencias o inconvenientes presentados con los servicios de las Compañías. Así mismo, declaro que estoy y estaré autorizando para entregar los datos personales a terceros que suministre a las Compañías en relación con las finalidades de Tratamiento antes indicadas. La información obtenida para el Tratamiento de mis datos personales la he suministrado de forma voluntaria y es verídica. 

`,
    acceptButton: "Aceptar",
    continue: "Continuar",
    thanks:
      "Gracias por aceptar la política de tratamiento de datos. Ya estás listo para invertir en a2censo."
  },
  creditHistory: {
    title: "Historial Crediticio",
    title2:
      "Hemos actualizado la autorización para consultar tu historial crediticio. Acéptala para poder continuar.",
    acceptButton: "Aceptar",
    continue: "Continuar",
    thanks:
      "Gracias por aceptar la nueva autorización para consultar tu historial crediticio. Ya estás listo para financiarte en a2censo."
  },
  register: {
    signUpReason: {
      invest: {
        title: "Quiero invertir",
        description: "Para hacer crecer mi dinero."
      },
      finance: {
        title: "Quiero financiarme",
        description: "Para hacer realidad mis proyectos empresariales."
      }
    },
    pymeRequirements: {
      tooltip: {
        title: "Algunos sectores económicos por ahora no podrán \nparticipar. ",
        titleBold: "Están restringidos: ",
        description:
          "Movimientos políticos, Juegos de azar, Casas de cambio, \nCooperativas, Originadores de crédito, Intermediación \nfinanciera, Comunidades religiosa, Agricultura, Ganadería, \nCaza, Silvicultura y pesca, Explotación de minas y \ncanteras, Administración pública y defensa, Planes de \nseguridad social de afiliación obligatoria, Actividades de \norganización extraterritoriales (incluyendo ONG’s), \nConstrucciones de obras civiles o vivienda."
      },
      send: "Continuar",
      title: "Requisitos",
      subtitle:
        "Ayúdanos con los siguientes datos para acompañarte en el proceso, recuerda que debes cumplir con todos los requisitos para continuar.",
      requirement1: "No pertenece a los sectores económicos excluidos",
      requirement2: "La empresa está constituida como persona jurídica",
      requirement3:
        "No se encuentra en proceso de liquidación, causal de disolución, acuerdo de reorganización empresarial u otra causal específica similar",
      requirement4:
        "Ventas anuales superiores a los $900 millones e inferiores a los $50.000 millones de pesos",
      requirement5:
        "Antigüedad mínima de 1 año facturando y ejerciendo su objeto social"
    },
    fullName: "Nombres y Apellidos",
    preRegister: "Pre Registro",
    complety: "Completado",
    naturalPerson: "Persona natural",
    legalPerson: "Persona jurídica",
    pymePerson: "Empresa en crecimiento",
    generalData: "Datos generales",
    contactPersonTitle: "Persona de contacto",
    firstName: "Primer nombre",
    secondName: "Segundo nombre",
    name: "Nombre",
    lastname: "Primer Apellido",
    secondLastname: "Segundo Apellido",
    companyName: "Razón social",
    dv: "DV",
    documentType: "ID",
    documentNumber: "Número de identificación",
    confirmDocument: "Confirmar número de identificación",
    email: "Correo electrónico",
    countryCode: "Cod país",
    phoneNumber: "Número de celular",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    terms1: "Al seleccionar, aceptas nuestros ",
    terms2: "términos y condiciones.",
    politics1: "Al seleccionar, aceptas nuestra ",
    politics2: "política de tratamiento de datos personales.",
    politics3:
      "política de tratamiento de datos personales para la persona de contacto.",
    hc1: "Al seleccionar, nos autorizas para que consultemos tu ",
    hc2: "historial crediticio.",
    continue: "Continuar",
    finalize: "Finalizar",
    save: "Guardar",
    saveAndContinue: "Guardar y continuar",
    loading: "Cargando...",
    goToSignIn: "Iniciar sesión",
    successSignUpTitle: "¡Cuenta creada!",
    successSignUpTitlePYME: "¡Hemos confirmado tu información!",
    successSignUpMessagePYME:
      "Inicia sesión y ayúdanos con algunos datos adicionales para ser parte de nuestra comunidad.",
    successSignUpMessage:
      "Inicia sesión con tus datos para continuar tu proceso en a2censo.",
    validations: {
      number: "Debe ser un valor numérico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        typePJ: "Solo se permiten números y letras en mayúscula sin tilde.",
        required: "Debes ingresar tu nombre"
      },
      firstName: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        typePJ: "Solo se permiten números y letras en mayúscula sin tilde.",
        required: "Debes ingresar tu primer nombre"
      },
      secondName: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        typePJ: "Solo se permiten números y letras en mayúscula sin tilde.",
        required: "Debes ingresar tu segundo nombre"
      },
      lastname: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        required: "Debes ingresar tu primer apellido"
      },
      secondLastname: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        required: "Debes ingresar tu segundo apellido"
      },
      docExpeditionDate: {
        required: "Debes ingresar tu fecha de expedición",
        invalid: "Fecha ingresada no es válida"
      },
      docExpeditionPlace: {
        required: "Debes ingresar el lugar de expedición de tu documento"
      },
      typeDoc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el tipo de documento"
      },
      doc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento"
      },
      docDate: {
        required: "Debes ingresar la fecha de expedición documento"
      },
      docCity: {
        required: "Debes ingresar el lugar de expedición del documento"
      },
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email",
        invalid: "No es un formato de email válido"
      },
      phoneCode: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el código de pais"
      },
      phone: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten numeros.",
        required: "Debes ingresar tu telefono",
        invalid: "Número inválido"
      },
      password: {
        min: "Muy Corto!",
        max: "Muy largo!",
        matches:
          "Debe tener mínimo 8 caracteres, (1) mayúscula, (1) minúscula, (1) número, (1) caracter especial",
        required: "Debes ingresar tu contraseña"
      },
      confirmPassword: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes confirmar tu contraseña",
        oneOf: "Las contraseñas debe coincidir"
      },
      dv: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "El código de verificación es requerido",
        invalid: "Código de verificación inválido"
      },
      confirmDocument: {
        required: "Debes confirmar tu número de documento",
        oneOf: "El número de documento debe coincidir"
      },
      contactPersonEmail: {
        oneOf:
          "El correo de la empresa debe ser diferente al correo del contacto personal"
      }
    },
    userValidation: {
      understand: "Entendido",
      title: "¡Lo sentimos!",
      button: "Ok",
      pyme: {
        errorCodes: {
          416: "Recuerda que por ser colaborador bvc no puedes financiarte en a2censo.",
          1002: "Recuerda que por ser accionista bvc no puedes financiarte en a2censo."
        }
      },
      errorCodes: {
        400: "En este momento no se puede realizar la acción solicitada. Inténtalo más tarde.",
        401: "En este momento no se puede realizar la acción solicitada. Inténtalo más tarde.",
        405: "En este momento no se puede realizar la acción solicitada. Inténtalo más tarde.",
        416: "Recuerda que por ser colaborador bvc solo podrás ser parte de a2censo invitando a familiares, amigos o conocidos.",
        500: "En este momento no se puede realizar la acción solicitada. Inténtalo más tarde. Esperamos que muy pronto esté solucionado.",
        1001: "Recuerda que por ser accionista y/o colaborador bvc solo podrás ser parte de a2censo invitando a familiares, amigos o conocidos.",
        1002: "Recuerda que por ser accionista bvc sólo podrás ser parte de a2censo invitando a familiares, amigos o conocidos.",
        1003: "Recuerda que no puedes ser parte de a2censo siendo accionista asociado de la BVC.",
        1012: "El correo electrónico que estás intentando ingresar ya existe.",
        1013: "El número de documento que estás intentando ingresar ya existe.",
        "019":
          "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        "056":
          "Estamos teniendo inconvenientes con la creación de tu cuenta. Comunicate con nosotros para más información.",
        1014: "Debes ingresar un número celular de un operador móvil colombiano.",
        1015: "Por seguridad tu contraseña debe tener como minimo siete (7) caracteres entre números, letras y al menos un (1) caracter especial dentro de su combinación.",
        1016: "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        1017: "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        1018: "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        1019: "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        "invalid-query":
          "Recuerda que para invertir en a2censo debes crear tu cuenta como inversionista",
        "016":
          "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        "017":
          "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento.",
        "018":
          "¡Lo sentimos! Estamos teniendo inconvenientes. Por favor intenta en otro momento."
      }
    },
    financialInfo: {
      tooltip: "Este valor corresponde a la resta de tus activos menos el patrimonio.",
      alertEquityInsuf: "El patrimonio debe ser menor o igual a los activos."
    }
  },
  form: {
    email: "Correo electrónico",
    firstName: "Nombre",
    lastName: "Apellido",
    send: "Enviar"
  },
  header: {
    createAccount: "Crear cuenta",
    a2censo: "Nosotros",
    showcase: "Vitrina",
    logout: "Cerrar sesión",
    lastSignIn: "Último ingreso: ",
    clientIp: "IP:",
    investments: "Mis inversiones",
    balance: "Mi balance",
    company: "Mi empresa",
    my: "Mi ",
    onlyCompany: " empresa",
    campaigns: "Mis campañas",
    payments: "Mis pagos",
    login: "Iniciar sesión",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    tooltipLogout: {
      areYouSure: "¿Estás seguro que quieres cerrar sesión?",
      message: "Se perderán los datos que no se hayan guardado.",
      continue: "No, continuar.",
      confirmExit: "Si, salir"
    },
    certificates: "Mis certificados"
  },
  footer: {
    termAndConditions: "Términos y condiciones",
    dataProcessingPolicy: "Política de tratamiento de datos",
    frequencyQuestions: "Preguntas frecuentes",
    resources: "Recursos",
    inversions: "Mis inversiones",
    contact: "Contáctanos",
    address: "Cra 7 No 71-21 | Torre B Piso 12, Bogotá D.C.",
    phone: "(601) 313 9000 - Opción 1",
    email: "a2censo@bvc.com.co",
    aProduct: "Un producto",
    alliance: "Aliados",
    support: "Apoyan",
    poweredBy: "Powered by",
    browse: "Navega",
    faq: "Preguntas frecuentes",
    legal: "Legal",
    siteMap: "Mapa del sitio",
    findUs: "Encuéntranos",
    followUs: "Síguenos por nuestras redes",
    text1: "¡No te quedes con dudas! ",
    text2: "Aquí estamos para ayudarte.",
    text3: "Nuestro equipo comercial está listo para atenderte",
    text4: "Correo comercial"
  },
  codeValidation: {
    title: "Código de verificación",
    subtitle: "Ingresa el código que enviamos a tu celular",
    noValidCode: "Código no válido",
    noReceived: "No he recibido el código.",
    askAgain: "Solicitar de nuevo",
    newCodeMessage: "Te hemos enviado un nuevo código",
    exceededLimitAttempts:
      "Has excedido el limite de intentos, por favor solicita un nuevo código."
  },
  personType: {
    whatKindOfPersonYouAre: "¿Qué tipo de persona eres?",
    naturalPerson: "Persona natural",
    naturalPersonRules:
      "Tienes una cédula colombiana o una cédula de extranjería, y tienes una cuenta bancaria en Colombia.",
    legalPerson: "Persona jurídica",
    legalPersonRules: "Te identificas con un NIT Colombiano.",
    pyme: "PYME",
    pymeRules: "The quick brown fox jumps over the lazy dog."
  },
  oops: {
    sorry: "Lo sentimos, no eres tú, ",
    itsUs: "somos nosotros.",
    tryAgain:
      "Estamos presentando algunos inconvenientes, por favor inténtalo más tarde.",
    goHome: "Regresar al inicio",
    conectionLost:
      "No tienes conexión a internet. Por favor revísala. ¡Te esperamos de regreso!",
    button: "Volver a cargar"
  },
  checkEmail: {
    checkYourEmail: "¡Revisa tu correo!",
    checkYourEmailPyme: "¡Revisa tu correo!",
    completedSuccessfully: "Has completado tus datos satisfactoriamente. ",
    completedSuccessfullyPyme:
      "Ya recibimos todos tus datos y te enviamos un mensaje para poder continuar. ",
    inYourEmail: "En tu correo encontrarás un mensaje de ",
    youMustUse: "confirmación que debes revisar para continuar",
    receivedData: "Ya recibimos todos tus datos y te enviamos un ",
    messageToContinue: "mensaje para poder continuar."
  },
  documentUploaded: {
    title: "¡Documento recibido!",
    subtitle:
      "Pronto te confirmaremos el estado de tu documento al correo electrónico que registraste, mientras esto sucede podrás invertir bajo los límites de un inversionista no calificado."
  },
  successView: {
    processFinishedSuccessfully: "Proceso finalizado Exitosamente"
  },
  forgotPassword: {
    title: "¿Olvidaste tu contraseña?",
    subtitle:
      "Ingresa el correo que registraste en a2censo para poder continuar.",
    continue: "Continuar",
    newPassword: "Nueva contraseña",
    email: "Correo electrónico",
    cancel: "Cancelar",
    error: "Este correo no está registrado en nuestra plataforma",
    newPasswordConfirmation: "Confirmar nueva contraseña",
    successTitle: "¡Contraseña reestablecida!",
    successDescription:
      "Ingresa con tus nuevos datos y explora todas nuestras campañas en a2censo.",
    resetTitle: "Restablecer contraseña",
    validations: {
      password: {
        min: "Muy Corto!",
        max: "Muy largo!",
        matches:
          "Debe tener mínimo (8) caracteres, (1) mayúscula, (1) minúscula, (1) número, (1) caracter especial sugerido ( ! @ # $ % ^ & * / . - _ ( ) + )",
        required: "Debes ingresar tu contraseña"
      },
      confirmPassword: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes confirmar tu contraseña",
        oneOf: "Las contraseñas debe coincidir"
      }
    }
  },
  forgotPasswordMethod: {
    title: "¿Olvidaste tu contraseña?",
    subtitle:
      "Te enviaremos un código para poder recuperarla. Indícanos en dónde quieres recibirlo",
    phoneOption: "Vía email",
    emailOption: "Vía SMS"
  },
  forgotPasswordOtp: {
    title: "Código de verificación",
    subtitle: "Ingresa el código que hemos enviado a tu correo electrónico.",
    subtitleSMS1:
      "Ingresa el código que recibiste al",
    subtitleSMS2: "a través de sms o voz.",
    infoSMS:
      "Ten en cuenta: si el SMS no es enviado, a través de una llamada al celular te indicaremos el código.",
    codeActive0: "Lo sentimos, tu código ha expirado, debes solicitar uno nuevo.",
    codeActive1: "Tu código estará activo durante",
    codeActive2: "Código de verificación incorrecto (Tienes 3 intentos)",
    codeActive3: "Código de verificación incorrecto (Tienes 2 intentos)",
    codeActive4: "Código de verificación incorrecto (Tienes 1 intento)",
    askAgain: "Solicitar de nuevo",
    noReceived: "No he recibido el código.",
    intermittentService:
      "Gracias por esperarnos. En un instante cargaremos la información",
    validCode: "Codigo válido",
    invalidCode: "Codigo no válido",
    expiredCode: "Este código ha expirado",
    tooManyAttempts:
      "¡Has gastado todos los intentos! Vuelve a intentar más tarde",
    blacklisted1: "Oops!",
    blacklisted2: "Algo salió mal, por favor intenta más adelante.",
    serviceDown1: "Oops!",
    serviceDown2: "Lo sentimos, no eres tú, somos nosotros.",
    serviceDown3:
      "Estamos presentando algunos inconvenientes por favor intenta más tarde."
  },
  smsOtpValidation: {
    failedAttempts: {
      title: "Lo sentimos, debes solicitar un nuevo código.",
      buttom: "Solicitar"
    },
    oops: {
      title: "Oops :(",
      info1: "En el momento tenemos inconvenientes para realizar el proceso",
      info2: "Intentalo más tarde ;)",
      buttom: "Ir a vitrina"
    }
  },
  enrollment: {
    challengeQuestionnaireAnswers: {
      title: "Confírmanos tu identidad",
      subtitle:
        "Ayúdanos respondiendo las siguientes preguntas para confirmar que eres tú,  y de esta manera puedas continuar",
      sendButton: "Enviar",
      modal: {
        title: "¡Lo sentimos!",
        subtitle1: "Al parecer algo salió mal con tus respuestas.",
        subtitle2: " Por favor intenta nuevamente.",
        subtitle3: "Has gastado todos los intentos.",
        subtitle4: "Vuelve más tarde.",
        button: "Intentar de nuevo"
      },
      oopsSubtitle:
        "Lo sentimos.\n No podemos continuar con tu proceso de vinculación.",
      oopsMessage:
        "Por favor contáctate con nosotros a través del correo\n a2censo@bvc.com.co o nuestra línea de servicio en el\n PBX (601) 313 9000",
      button: "Ok",
      errorCode: {
        "EVIDENTE-05":
          "Por el momento no contamos con información suficiente para corroborar tu identidad. Intenta vincularte más adelante.",
        "EVIDENTE-06":
          "En este momento no podemos validar tus datos. Intenta más tarde.",
        "EVIDENTE-07":
          "En este momento no podemos validar tu número de identificación. Intenta más tarde.",
        "EVIDENTE-08":
          "En este momento no podemos validar tu documento de identificación. Intenta más tarde.",
        "EVIDENTE-09": "Has gastado todos tus intentos. Prueba más tarde.",
        "EVIDENTE-10":
          "Estamos teniendo fallas técnicas. Por favor vuelve mas tarde.",
        "EVIDENTE-11":
          "Estamos teniendo fallas técnicas. Por favor vuelve mas tarde.",
        "LISTAS_RESTRICTIVAS-301":
          "¡Lo sentimos! Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
        "LISTAS_RESTRICTIVAS-302":
          "¡Lo sentimos! Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
        "LISTAS_RESTRICTIVAS-303":
          "¡Lo sentimos! Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
        "GENERACION_QUESTIONARIO-00":
          "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento.",
        "GENERACION_QUESTIONARIO-02":
          "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento.",
        "GENERACION_QUESTIONARIO-07": "Debes estar bancarizado para continuar.",
        "GENERACION_QUESTIONARIO-10":
          "Has alcanzado el número máximo de intentos por día. Por favor vuelve a intentarlo en otro momento.",
        "GENERACION_QUESTIONARIO-11":
          "Has alcanzado el número máximo de intentos por mes. Por favor vuelve a intentarlo en otro momento.",
        "GENERACION_QUESTIONARIO-12":
          "Has alcanzado el número máximo de intentos por año. Por favor vuelve a en otro momento.",
        "GENERACION_QUESTIONARIO-13":
          "Has excedido la cantidad de ingresos permitidos. Por favor vuelve en otro momento.",
        "GENERACION_QUESTIONARIO-14":
          "Has excedido la cantidad de ingresos permitidos. Por favor vuelve en otro momento.",
        "GENERACION_QUESTIONARIO-15":
          "Has excedido la cantidad de ingresos permitidos. Por favor vuelve en otro momento.",
        "GENERACION_QUESTIONARIO-17":
          "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento.",
        "GENERACION_QUESTIONARIO-18":
          "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento.",
        "GENERACION_QUESTIONARIO-19":
          "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento."
      }
    },
    financialInfo: {
      title: "Información financiera",
      tooltipInfo: "Información financiera",
      subtitle:
        "Recuerda que juntos creamos confianza, por esa razón tus datos financieros deben ser reales para poderte guiar.",
      assets: "Activos",
      monthly_income: "Ingresos mensuales",
      equity: "Patrimonio",
      monthly_expenses: "Egresos mensuales",
      profit: "Utilidad Neta",
      saveAndContinue: "Guardar y continuar",
      saving: "Guardando...",
      passives: "Pasivos"
    },
    organizationalStructure: {
      title: "Representante y socios",
      tooltipInfo:
        "Cuéntanos quién es el representante legal de tu empresa y los socios con más del 5% de participación.",
      legal: "Representante legal",
      associates: "Socios",
      saveAndContinue: "Guardar y continuar",
      saving: "Guardando...",
      legalRepresentative: {
        completeInfo: "Completar información"
      },
      associate: {
        add: "Agregar",
        participation: "participación"
      },
      partiallyDoesNotPassModal: {
        title: "¡Recibimos tus datos!",
        text:
          "Con estos validaremos tu información y te comunicaremos vía correo la respuesta.",
        continueButton: "Continuar"
      },
      listValidationsLegal: {
        sorry: "¡Lo sentimos!",
        rrhhListText:
          "Recuerda que por ser colaborador bvc no puedes continuar con la vinculación.",
        accionistListText:
          "Recuerda que por ser accionista bvc no puedes continuar con la vinculación.",
        experianListText:
          "Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
        continueButton: "OK"
      }
    }
  },
  generalInformation: {
    title: "Información general",
    subTitle:
      ", cuentanos más de ti! con tus datos personales y de contactos podemos asegurarte una mejor experiencia.",
    subTitleLegal:
      ", cuéntanos más de tu empresa! Con unos datos adicionales podremos conocerte para asegurarte la mejor experiencia.",
    generalData: "Datos generales",
    countryDataQuestion: "¿En qué parte del mundo estás?",
    name: "Nombre",
    secondName: "Otros nombres",
    lastName: "Primer apellido",
    secondLastName: "Segundo apellido",
    documentType: "Tipo documento",
    documentIDType: "ID",
    documentNumber: "Número de identificación *",
    email: "Correo electrónico",
    countryCode: "Cód. país",
    phoneNumber: "Número de celular",
    documentCity: "Lugar de expedición",
    documentDate: "Fecha de expedición",
    birthDayData: "Datos de nacimiento",
    dateConstitutionData: "Fecha de constitución",
    birthDay: "Fecha de nacimiento",
    selectCountry: "Selecciona el país",
    selectCity: "Selecciona la ciudad",
    birthDayDate: "Fecha de nacimiento (aaaa-mm-dd)",
    birthDayDateConstitution: "Fecha de constitución (aaaa-mm-dd)",
    country: "País",
    countryPlaceholder: "Selecciona el país",
    city: "Ciudad",
    cityPlaceholder: "Selecciona la ciudad",
    state: "Departamento",
    statePlaceholder: "Selecciona el departamento",
    address: "Dirección",
    emailPlaceholder: "example@domain.com",
    contactData: "Datos de contacto",
    cityCode: "Indic. ciudad",
    homePhone: "Número de télefono",
    publicResourcesManager: "¿Administras recursos públicos?",
    expeditionPlace: "Lugar de expedición",
    USMoneyTransfer: "¿Estás apoderado/a permanentemente en Estados Unidos?",
    publicExposed: "¿Te consideras una persona públicamente expuesta?",
    yes: "Si",
    no: "No",
    inputPlaceholder: "Cuéntanos de tu empresa",
    placeholder: {
      birthDayDateConstitution: "aaaa - mm - dd"
    },
    tooltips: {
      generalInfoTooltip: "Aquí podrás incluir tu información personal.",
      birthTooltip: "Especifica tu lugar y fecha de nacimiento.",
      contactTooltip: "Añade tu información de contacto.",
      publicResourcesTooltip:
        "Especifica si eres una persona que administra recursos públicos.",
      familyTooltip:
        "Si alguno de tus familiares es una persona pública añadela aquí.",
      fatcaTooltip: "Especifica si manejas recursos en los Estados Unidos",
      publicExposedPerson: `Eres una Persona Públicamente Expuesta (PEP) cuando:
        \n1. Cumples con lo establecido en el Decreto 1674 de 2016.
         2. Representas legalmente una organización internacional.
         3. Eres públicamente reconocido.`,
      familyPublicExposedPerson: `Se considera que tienes un familiar que es una Persona Públicamente Expuesta (PEP) cuando:
        \n1. Están casados o conviven
        \n2. Comparten segundo grado de consanguinidad y/o afinidad - Hermanos, abuelos, nietos, yernos, nueras, suegros y cuñados
        \n3. Es un hijo adoptivo o padres adoptantes.`,
      ciiu: `El código CIIU hace referencia a la actividad económica que desempeñas, \npodrás consultarlo en tu RUT o en la página de la DIAN. Recuerda que por ahora, \nsi perteneces a alguno de los siguientes sectores no podrás participar:
        \n1· Actividades de juegos de azar y apuestas
        2· Actividades de otras asociaciones
        3· Actividades de asociaciones religiosas
        4· Actividades de asociaciones políticas
        5· Actividades de otras asociaciones N.C.P
        6· Actividades de las casas de cambio
        7· Actividades de los profesionales de compra y venta de divisas
        8· Otras actividades auxiliares de las actividades de servicios financieros N.C.P
        9· Extracción de otras minas y canteras`
    },
    ciiuTitlePJ: "¿Tienes Sector Económico secundario? (Opcional)",
    ciiuTitlePN: "¿Cual es tu actividad económica? (Código CIIU)",
    ciiuPlaceholder: "Escribe el código (Ej.O143)",
    validations: {
      birth_country: {
        required: "Debes ingresar tu país de nacimiento"
      },
      birth_state: {
        required: "Debes ingresar tu departamento de nacimiento"
      },
      birth_city: {
        required: "Debes ingresar tu ciudad de nacimiento"
      },
      birth_date: {
        required: "Debes ingresar tu fecha de nacimiento",
        valid: "No es un formato de email válido",
        age: "Debes ser mayor de edad para continuar..."
      },
      constitution_date: {
        required: "Debes ingresar la fecha de constitución"
      },
      contact_country: {
        required: "Debes ingresar tu país"
      },
      contact_state: {
        required: "Debes ingresar tu departamento"
      },
      city: {
        required: "Debes ingresar tu ciudad"
      },
      address: {
        min: "Muy corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu dirección"
      },
      landline_country_code: {
        required: "Debes ingresar tu código de país"
      },
      landline_city_code: {
        notValid: "Debe ser un número",
        required: "Debes ingresar tu código de ciudad",
        range: "Debe ser entre 1 y 99"
      },
      landline: {
        min: "Muy corto!",
        max: "Muy largo!",
        notNumber: "Debe ser un número",
        notValid: "Número no valido",
        required: "Debes ingresar un número de telefono"
      },
      ciiu: {
        invalid:
          "El CIIU ingresado pertenece a los sectores restringidos para participar en a2censo",
        required: "Debes ingresar un CIIU"
      },
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu nombre",
        type: "Solo se permiten letras"
      },
      rol: {
        type: "Solo se permiten letras, números, guión, punto, / y &"
      },
      second_name: {
        type: "Solo se permiten letras"
      },
      last_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu apellido",
        type: "Solo se permiten letras"
      },
      second_last_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu segundo apellido",
        type: "Solo se permiten letras"
      },
      position: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu cargo",
        type: "Solo se permiten letras"
      },
      entity: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu entidad o empresa actual",
        type: "Solo se permiten letras"
      },
      whatIsYourBusinessAdress: {
        max: "Muy largo!",
        required: "Debes ingresar una dirección comercial"
      },
      businessDescription: {
        max: "El campo supera el número de caracteres permitidos!",
        required: "Debes ingresar una descripción"
      },
      socialNetworks: {
        max: "El campo supera el número de caracteres permitidos!",
        url: "Deben ser un link valido"
      }
    },
    whatIsYourBusinessAdress: "¿Cuál es tu dirección Comercial?",
    socialNetworks: "Redes sociales",
    socialNetworksPlaceholders: {
      instagram: "Instagram (instagram.com/nombredeusuario)",
      facebook: "Facebook (facebook.com/nombredeusuario)",
      twitter: "Twitter (twitter.com/nombredeusuario)",
      linkein: "LinkedIn (linkedin.com/in/nombredeusuario)",
      youtube: "Youtube (youtube.com/user/nombredeusuario)",
      web: "Web (tuweb.com)"
    },
    socialNetworksTitles: {
      instagram: "Instagram",
      facebook: "Facebook",
      twitter: "Twitter",
      linkedin: "LinkedIn",
      youtube: "Youtube",
      web: "Web"
    },
    socialNetworksTitlesPlaceHolders: {
      instagram: "Link de la cuenta de Instagram",
      facebook: "Link de la cuenta de Facebook",
      twitter: "Link de la cuenta de Twitter",
      linkedin: "Link de la cuenta de LinkedIn",
      youtube: "Link de la cuenta de Youtube",
      web: "Link de la cuenta de Web"
    },
    businessDescription: "Descripción de la empresa",
    usaInfluence: "¿Estás apoderado/a permanentemente en Estados Unidos?",
    productiveSector: "Sector productivo de la empresa",
    usaAnswers: {
      addressInUsa: "¿Tienes dirección permanente en Estados Unidos?",
      phoneInUsa: "¿Tienes teléfono permanente en Estados Unidos?",
      sharesInUsa:
        "¿Tienes participación accionaria en una empresa en Estados Unidos?",
      sendMoneyUsa: "¿Haces giros de recursos a Estados Unidos?",
      leftIdInUsa: "¿Has renunciado a la ciudadanía Estadounidense?",
      usaNationality: "¿Tienes nacionalidad Estadounidense?",
      travelToUsa: "¿Has visitado Estados Unidos?"
    }
  },
  summary: {
    title: "Tu resumen",
    title2: "Información financiera",
    title3: "¿Cuál es tu propósito?",
    textTitle:
      ", asegúrate que todos tus datos estén correctos antes de finalizar.",
    generalData: "Datos generales",
    bornData: "Datos de nacimiento",
    contactData: "Datos de contacto",
    name: "Nombre",
    lastname: "Primer Apellido",
    secondLastName: "Segundo Apellido",
    documentType: "Tipo documento",
    documentNumber: "Número de identificación",
    expeditionPlace: "Lugar de expedición",
    documentExpeditionPlace: "Lugar de expedición",
    address: "Escribe tu dirección",
    email: "Correo electrónico",
    country: "Selecciona el país",
    country2: "País de residencia",
    city_expedition: "Selecciona la ciudad",
    birth_city: "Selecciona la ciudad",
    birth_department: "Selecciona el departamento",
    department: "Selecciona el departamento",
    city: "Selecciona la ciudad",
    city3: "Ciudad de residencia",
    expedition_date: "Fecha de expedición",
    date1: "Fecha",
    date2: "Fecha de nacimiento",
    countryCode: "Cod país",
    cityCode: "Indic. ciudad",
    landline: "Número de télefono",
    phoneNumber: "Número de celular",
    resources1: "¿Administras recursos públicos?",
    resourcesUnitedState: "¿Haces giros de recursos a Estados Unidos?",
    resourcesUS1: "¿Estas apoderado permanentemente en Estados Unidos?",
    resourcesUS2: "¿Tienes dirección permanente en Estados Unidos?",
    resourcesUS3: "¿Tienes teléfono permanente en Estados Unidos?",
    resourcesUS4:
      "¿Tienes participación accionaria en una empresa en Estados Unidos?",
    resourcesUS5: "¿Haces giros de recursos a Estados Unidos?",
    publicPerson: "¿Te consideras una Persona Públicamente Expuesta (PEP)?",
    yes: "Si",
    no: "No",
    publicPersonType: "¿Qué tipo de PEP eres?",
    pep1: "PEP Tipo 1",
    pep2: "PEP Tipo 2",
    pep3: "PEP Tipo 3",
    familyPublicPersonType:
      "¿Tienes familiares considerados Persona Públicamente Expuesta (PEP)?",

    familyTitle: "Familiar #",
    familyNames: "Nombres",
    familyLastnames: "Apellidos",
    familyPosition: "Cargo que desempeña actualmente",
    familyEntity: "Entidad o empresa actual",
    familyNew: "Agregar otro familiar",
    financialInfo1: "Activos",
    financialInfo2: "Ingresos mensuales",
    financialInfo3: "Patrimonio",
    financialInfo4: "Egresos mensuales",
    financialInfo5: "Pasivos",
    pourpose: "Selecciona tu próposito",
    continue: "Finalizar",
    loading: "Cargando...",
    ciiuTitle: "¿Cuál es tu actividad económica? (Código CIIU)",
    ciiuPlaceholder: "Escribe el código (Ej.O143)",
    validations: {
      number: "Debe ser un valor numérico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu nombre",
        type: "Solo se permiten letras."
      },
      lastname: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu apellido"
      },
      typeDoc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el tipo de documento"
      },
      doc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento"
      },
      address: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu dirección"
      },
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email"
      },
      phoneCode: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el codigo de pais"
      },
      phone: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten numeros.",
        required: "Debes ingresar tu telefono"
      },
      country: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el país"
      },
      city: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar la ciudad"
      },
      birth_city: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar la ciudad"
      },
      ciiu: {
        invalid: "El CIIU es inválido",
        required: "Debes ingresar un CIIU"
      }
    }
  },
  summarySuccess: {
    title: "¡Estamos listos!",
    natural: {
      paragraph1:
        "Ya puedes invertir en proyectos de empresas Colombianas, hazlo directamente desde tu cuenta explorando nuestra vitrina o cargando tu saldo disponible."
    },
    legal: {
      paragraph1:
        "Ya solo falta un paso para empezar a invertir, el certificado de Existencia y Representación Legal se encuentra en evaluación. Podrás acceder a invertir una vez este documento sea aprobado por nuestro personal y recibas la notificación vía email."
    },
    goToShowcases: "Ver vitrina",
    myBalance: "Cargar balance"
  },
  confirmData: {
    titleSection: "Confírmanos tus datos",
    sectionDescription:
      ", antes de continuar verifica que todos tus datos estén correctos. ¡Ten en cuenta que después no podrás editarlos!",
    titleForm: "Datos generales",
    name: "Nombre",
    lastname: "Primer Apellido",
    secondLastname: "Segundo Apellido",
    documentType: "ID",
    documentNumber: "Número de identificación",
    confirmDocument: "Confirmar número de identificación",
    email: "Correo electrónico",
    countryCode: "Cod país",
    phoneNumber: "Número de celular",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    expeditionDate: "Fecha de expedición",
    expeditionPlace: "Lugar de expedición",
    saveAndContinue: "Guardar y continuar",
    DV: "Dígito de verificación",
    existenceCertificate:
      "Carga tu certificado de existencia (Documento en .PDF o .JPG)",
    uploadDocument: "Subir documento",
    ciiuTitle:
      "¿Cuál es el sector económico principal de tu empresa? (Código CIIU)",
    ciiuOptionalTitle: "¿Tienes Sector Económico secundario? (Opcional)",
    ciiuPlaceholder: "Escribe el código (Ej.O143)",
    tooltips: {
      ciiu: {
        title:
          "Algunos sectores económicos por ahora no podrán participar. Verifica el siguiente \n listado para saber si puedes hacer parte de esta comunidad:",
        description: `1· Actividades de juegos de azar y apuestas
          2· Actividades de otras asociaciones
          3· Actividades de asociaciones religiosas
          4· Actividades de asociaciones políticas
          5· Actividades de otras asociaciones N.C.P
          6· Actividades de las casas de cambio
          7· Actividades de los profesionales de compra y venta de divisas
          8· Otras actividades auxiliares de las actividades de servicios financieros N.C.P
          9· Extracción de otras minas y canteras`
      },
      doc: ""
    },
    validations: {
      number: "Debe ser un valor numérico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras.",
        required: "Debes ingresar tu nombre"
      },
      last_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras.",
        required: "Debes ingresar tu primer apellido"
      },
      second_last_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras.",
        required: "Debes ingresar tu segundo apellido"
      },
      existence_certificate: {
        required: "Debes ingresar tu certificado de existencia"
      },
      typeDoc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el tipo de documento"
      },
      doc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento"
      },
      doc_expedition_date: {
        required: "Debes ingresar tu fecha de expedición"
      },
      expedition_place: {
        required: "Debes ingresar el lugar de expedición del documento"
      },
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email"
      },
      phoneCode: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el codigo de pais"
      },
      phone: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten numeros.",
        required: "Debes ingresar tu telefono"
      },
      ciiu: {
        invalid:
          "El CIIU ingresado pertenece a los sectores restringidos para participar en a2censo",
        required: "Debes ingresar un CIIU"
      },
      dv: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar un DV"
      }
    },
    oopsSubtitle: "¡Algo salió mal!",
    oopsMessage: "Por favor intenta más adelante.",
    loading: "cargando...",
    experianErrorButton: "Ok",
    errorCode: {
      "EVIDENTE-05":
        "Por el momento no contamos con información suficiente para corroborar tu identidad. Intenta vincularte más adelante.",
      "EVIDENTE-06":
        "En este momento no podemos validar tus datos. Intenta más tarde.",
      "EVIDENTE-07":
        "En este momento no podemos validar tu número de identificación. Intenta más tarde.",
      "EVIDENTE-08":
        "En este momento no podemos validar tu documento de identificación. Intenta más tarde.",
      "EVIDENTE-09": "Has gastado todos tus intentos. Prueba más tarde.",
      "EVIDENTE-10":
        "Estamos teniendo fallas técnicas. Por favor vuelve mas tarde.",
      "EVIDENTE-11":
        "Estamos teniendo fallas técnicas. Por favor vuelve mas tarde.",
      "LISTAS_RESTRICTIVAS-301":
        "¡Lo sentimos! Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
      "LISTAS_RESTRICTIVAS-302":
        "¡Lo sentimos! Estamos teniendo inconvenientes y no podemos continuar con tu vinculación.",
      "LISTAS_RESTRICTIVAS-303":
        "Estamos teniendo fallas técnicas. Por favor vuelve mas tarde.",
      PROBLEM_WITH_DOCUMENT:
        "Hemos encontrado un inconveniente con el documento ingresado, por favor comunícate con servicio al cliente."
    }
  },
  purpose: {
    titleSection: "Tu propósito",
    titleList: "¿Qué te motiva a invertir en a2censo?",
    titleListLegal: "¿Qué motiva a tu empresa a invertir?",
    saveAndContinue: "Guardar y continuar"
  },
  login: {
    title: "¡Bienvenido de nuevo!",
    firstTime: "¿Primera vez en a2censo?",
    login: "Iniciar sesión",
    loading: "Cargando...",
    forgotPassword: "¿Olvidaste tu contraseña?",
    register: "Regístrate",
    lastSignIn: "Último ingreso: ",
    clientIp: "IP:",
    invalidCredentials:
      "No conocemos tus datos, registrate ó rectificalos para poder continuar.",
    tooManyAttempts:
      "Ops!! Algo salió mal, por tu seguridad hemos suspendido la cuenta.",
    goToRegister: "Registrarme",
    goToBack: "Regresar",
    oopsSubtitle: "¡Lo sentimos!",
    oopsMessage: "Estamos teniendo inconvenientes. Inténtalo más tarde.",
    button: "Ok",
    buttonModal033: "Entiendo",
    errorCodes: {
      "033":
        "Revisa tu correo, allí encontrarás un mensaje de confirmación que debes revisar para continuar."
    }
  },
  vinculationExpired: {
    linkExpired: "¡Este link ha expirado!",
    linkExpiredDescription:
      "Este link ha superado los 30 días desde que fue enviado. Registrate de nuevo y continua con la vinculación en a2censo."
  },
  simulator: {
    capitalGracePeriod: "Periodo de gracia de capital",
    capitalPayment: "Pago capital",
    interestPaymnet: "Pago intereses",
    downloadDetail: "Descargar detalle",
    interestGracePeriod: "Periodo de gracia de interes",
    goal: "Meta:",
    collectedAmount: "Monto recaudado:",
    campaign: "Campaña:",
    seeVideoLearn: "Aprende a utilizar el simulador.",
    seeVideo: "Ver video",
    listCampaign: {
      loading: "Cargando...",
      superTitle: "Simula tu inversión en Deuda",
      modalTitle: "Selecciona una campaña para usar el simulador",
      modalTitleMobile: "Seleccione una campaña para simular:",
      rate: "Tasa: fija",
      term: "Plazo",
      interesPlusCapitalPayment: "Pago interes + capital"
    },
    simulate: "Simula tu inversión",
    fixedFee: "Tasa: fija",
    ea: "E.A",
    months: "meses",
    paymentPlusCapital: "Pago intereses",
    howMuch: "¿Cuánto te gustaría invertir?",
    minimum: "Monto minímo de inversión",
    total: "Recibes un total de",
    earn: "Ganas",
    periodicFeeCapital: "Recibes periódicamente",
    periodicFeeGrace: "Recibes durante el periodo de gracia",
    periodicFeeIntereses: "Recibes periódicamente de pago de intereses",
    invest: "Invertir",
    monthly: "Mensual",
    quarterly: "Trimestral",
    toExpiration: "Al vencimiento",
    term: "Plazo",
    investmentError:
      "Monto inválido: El monto mínimo a invertir es $200.000 y los incrementos deben ser múltiplos de $50.000",
    error: "Ocurrio un error en la carga del simulador"
  },
  associate: {
    sectionDescription:
      "Conocer los datos de los socios con más del 5% de participación en tu empresa nos ayuda dimensionar toda tu estructura",
    personTypeTitle: "Escoge el tipo de persona del socio.",
    natural: "Persona natural",
    company: "Persona jurídica",
    generalDataTitle: "Datos generales",
    name: "Nombre",
    firstName: "Primer nombre",
    otherName: "Otros nombres",
    companyName: "Razón social *",
    lastName: "Primer apellido",
    secondLastName: "Segundo apellido",
    companyDocumentType: "NIT",
    documentType: "ID",
    documentNumber: "Número de identificación *",
    documentNumberRepeat: "Número de identificación ya asociado",
    dv: "DV *",
    expeditionDate: "Fecha de expedición",
    expeditionPlace: "Lugar de expedición",
    expeditionCountry: "País de expedición",
    expeditionCity: "Ciudad de expedición",
    expeditionDepartment: "Departamento de expedición",
    countryExpeditionPlace: "País de expedición",
    countryOfResidence: "País de residencia ",
    nationality: "Nacionalidad",
    ciiuTitle:
      "¿Cuál es el Sector Económico Principal de la empresa? (Código CIIU)",
    ciiu: "Escribe el sector o el código",
    percent: "Ingresa el porcentaje de participación de este socio  (Mín. 5%)",
    publicResourcesManager: "¿Administras recursos públicos?",
    USMoneyTransfer: "¿Estás apoderado/a permanentemente en Estados Unidos?",
    publicExposed: "¿Te consideras una persona públicamente expuesta?",
    yes: "Si",
    no: "No",
    country: "Selecciona el país",
    department: "Selecciona el departamento",
    city: "Selecciona la ciudad",
    saving: "Guardando...",
    saveAndContinue: "Guardar y continuar",
    residence: "País de residencia",
    alertNITE:
      "Ten en cuenta que la Identificación tributaria o equivalente NIT (E) corresponde a personas jurídicas residentes en el exterior.",
    alertNIT:
      "Ten en cuenta que NIT aplica únicamente para personas jurídicas residentes en Colombia.",
    validations: {
      number: "Debe ser un valor numérico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        typePJ: "Solo se permiten números y letras en mayúscula sin tilde.",
        required: "Debes ingresar tu nombre"
      },
      second_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        required: "Debes ingresar tu apellido"
      },
      business_name: {
        required: "Debes ingresar tu razón social"
      },
      last_name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras mayúsculas y sin tilde.",
        required: "Debes ingresar tu apellido"
      },
      document_type: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el tipo de documento"
      },
      document_number: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento",
        repeated: "Socio repetido",
        invalid: "Documento inválido",
        paMax:
          "Ingresa únicamente números o letras, sin caracteres especiales ni espacios.",
        ceMax:
          "Ingresa únicamente números, sin caracteres especiales ni espacios.",
        nite:
          "Ingresa únicamente números o letras, sin caracteres especiales ni espacios",
        number:
          "Ingresa únicamente números, sin caracteres especiales ni espacios."
      },
      document_date: {
        required: "Debes ingresar tu fecha de expedición"
      },
      alertsInfo: {
        CE:
          "La Cédula de Extranjería ingresada debe ser expedida por Migración Colombia."
      },
      expedition_place: {
        required: "Debes ingresar el lugar de expedición del documento"
      },
      expedition_country: {
        required: "Debes ingresar el lugar de expedición del documento"
      },
      country: {
        required: "Debes seleccionar el país"
      },
      city: {
        required: "Debes seleccionar la ciudad"
      },
      department: {
        required: "Debes seleccionar el departamento"
      },
      reason: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu razón social"
      },
      dv: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "El código de verificación es requerido",
        invalid: "Código de verificación inválido"
      },
      ciiu: {
        invalid: "El CIIU es inválido",
        required: "Debes ingresar un CIIU"
      },
      position: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu cargo",
        type: "Solo se permiten letras"
      }
    },

    associateSaveErrorTitle: "Error al registrar el asociado",
    associateSaveError:
      "El asociado presenta un error con sus datos y no puede ser registrado.",
    errorTitle: "¡Lo sentimos!",
    oopsSubtitle:
      "Lo sentimos. No podemos continuar con tu proceso de vinculación.",
    oopsMessage:
      "Por favor contáctate con nosotros a través del correo a2censo@bvc.com.co o a nuestra línea de servicio en el PBX (601) 313 9000",
    tooltips: {
      generalInfoTooltip:
        "Aquí podrás incluir la información personal de tu asociado.",
      publicResourcesTooltip:
        "Especifica si eres una persona que administra recursos públicos.",
      familyTooltip:
        "Si alguno de tus familiares es una persona pública añadela aquí.",
      fatcaTooltip: "Especifica si manejas recursos en los Estados Unidos",
      publicExposedPerson: `Eres una Persona Políticamente Expuesta (PEP) cuando:
        \n1. Cumples con lo establecido en el Decreto 1674 de 2016.
        2. Representas legalmente una organización internacional.
        3. Eres públicamente reconocido.                        `,
      associatePercent: "Especifica porcentaje de participación del asociado.",
      ciiu:
        "El código CIIU hace referencia a la actividad económica que desempeñas, <br>podrás consultarlo en tu RUT o en la página de la DIAN."
    }
  },
  legalRepresentative: {
    title: "Representante Legal",
    subtitle:
      "Conocer los datos del representante legal de tu empresa nos ayuda a fortalecer la confianza en nuestra relación. Recuerda ingresar sus nombres y apellidos completos como aparecen en el documento de identificación.",
    legalRepresentativeErrorTitle: "¡Lo sentimos!",
    legalRepresentativeError:
      "El representante legal presenta un error con sus datos y no puede ser registrado.",
    return: "Regresar",
    tooltips: {
      generalInfoTooltip: "Aquí podrás incluir tu información personal.",
      publicResourcesTooltip:
        "Especifica si eres una persona que administra recursos públicos.",
      familyTooltip:
        "Si alguno de tus familiares es una persona pública añadela aquí.",
      fatcaTooltip: "Especifica si manejas recursos en los Estados Unidos",
      publicExposedPerson:
        "Eres una Persona Públicamente Expuesta (PEP) cuando: %br 1. Cumples con lo establecido en el Decreto 1674 de 2016. %br 2. Representas legalmente una organización internacional.%br 3. Eres públicamente reconocido."
    }
  },
  notice: {
    title: "Novedades",
    article:
      "Bolsa de valores capacitará a empresarios alrededor del país sobre plataforma a2censo",
    published: "Publicado:"
  },
  featured: {
    title: "Nuestros protagonistas",
    collect: "Monto recaudado",
    titlecampaing: "Chök",
    price: "COP 20MM"
  },
  crowdExplication: {
    title: "Así funciona el Crowdfunding de inversión",
    subtitle: "Se abre una campaña",
    paragraph: "Una empresa publica su proyecto en la plataforma.",
    subtitle2: "Elige en qué invertir",
    paragraph2:
      "Explora las campañas y selecciona los proyectos con los que te identificas.",
    subtitle3: "Une tus recursos",
    paragraph3:
      "Invierte fácil tu dinero de una forma digital y súmalo junto al de otras personas.",
    subtitle4: "Se cierra la campaña",
    paragraph4: "La empresa recibe el dinero y pone en marcha su proyecto.",
    subtitle5: "Recibe tus ganancias",
    paragraph5:
      "La empresa comenzará a saldar su deuda y tú recibes tu dinero con las condiciones pactadas."
  },
  headerVideo: {
    title: "Creer en proyectos Colombianos nos hace grandes",
    subtitle: "¿Estás listo para dar el primer paso?",
    paragraph:
      "Descubre la nueva forma de hacer crecer tu dinero siendo parte de la primera comunidad de crowdfunding de inversión del país"
  },
  questions: {
    title: "¿Necesitas ayuda?",
    subtitle: "Déjanos tu mensaje y nuestro equipo te contactará.",
    formname: "Nombre completo",
    pymeFormName: "Razón social",
    pymeFormContactName: "Nombre de contacto",
    pymeFormContactPhone: "Teléfono de contacto",
    formmail: "Correo electrónico",
    formdescription: "Mensaje",
    acceptpolitic: "Al seleccionar, aceptas nuestra ",
    acceptpoliticLink: "política de tratamiento de datos personales.",
    acceptterms: "Al seleccionar, aceptas nuestros ",
    accepttermsLink: "términos y condiciones.",
    titleMessage: "¡Gracias!",
    thanks:
      "Tu mensaje se ha enviado correctamente, una  de nuestras personas de soporte se prondrá en contacto contigo a través de correo electrónico. Por ahora sigue explorando la plataforma que hemos creado para tí.",
    thanksTitle: "¡Estamos aquí para ti!",
    thanksText1:
      "Recibimos tu mensaje y pronto te contactaremos al correo registrado para ayudarte.",
    thanksExplore: "Explorar"
  },
  tabs: {
    buttonModalText: "¡Me interesa!",
    invest: "Invierte",
    financeYourself: "Finánciate",
    questions: "Preguntas",
    title: "1. Regístrate",
    paragraph:
      "Con unos datos básicos podrás tener tu cuenta propia en a2censo.",
    title2: "2. Enamórate",
    paragraph2:
      "Explora las campañas abiertas en a2censo y selecciona aquellas con las que te identificas.",
    title3: "3. Invierte desde $200.000",
    paragraph3: "De manera ágil y 100% digital en tus campañas favoritas.",
    title4: "4. Tu inversión crece",
    paragraph4:
      "Comienza a recibir tu capital + intereses. Así podrás invertir en otras compañías.",
    title5: "1. Contactate con nosotros",
    paragraph5:
      "¿Eres una empresa colombiana y tienes proyectos para financiar? ¡Escríbenos para acompañarte en el proceso!",
    title6: "2. Establece tu campaña",
    paragraph6:
      "Compártenos la información de tu empresa, proyecto a financiar y las condiciones que ofreces para evaluarlo.",
    title7: "3. Conéctate con tus inversionistas",
    paragraph7:
      "Publicaremos tu campaña en a2censo para que todos te conozcan e inviertan en tu proyecto.",
    title8: "4. Haz realidad tu proyecto",
    paragraph8:
      "Recibe tu dinero, ejecuta tu proyecto y salda tu deuda de acuerdo a las condiciones pactadas inicialmente.",

    question: "1. ¿Por qué confiar en a2censo?",
    answer:
      "Somos una plataforma de crowdfunding de deuda creada y respaldada por la Bolsa de Valores de Colombia, estamos vigilados por la S.F.C. y contamos con alianzas estratégicas con los principales organizaciones de desarrollo empresarial, innovación y emprendimiento.",

    question2: "2. ¿Qué nos hace diferentes?",
    answer2:
      "Nos aseguramos que tu dinero llegue a las manos de empresarios colombianos, obtienes una rentabilidad diferencial y cuentas con un respaldo de hasta el 50% de tu inversión.",

    question3: "3. ¿Cuáles son los requisitos para invertir?",
    answer3:
      "¡Es muy sencillo! Debes tener una cuenta en a2censo y contar con una cuenta bancaria colombiana.",

    question4: "4. ¿Cuando comienzas a obtener ganancias?",
    answer4:
      "Las campañas se cerrarán una vez alcanzado el monto esperado. Si la campaña cierra exitosamente comenzarás a recibir tu dinero de acuerdo a las condiciones pactadas en el momento de la inversión.",

    question5: "5. ¿Cómo contactar a a2censo?",
    answer5:
      "Puedes escribirnos al correo a2censo@bvc.com.co o através de la plataforma y nosotros te responderemos a la mayor brevedad.",
    additionalText:
      "Para más información consulta nuestra sección de preguntas frecuentes."
  },
  investmentSummary: {
    oneStepAhead: "Un paso más cerca",
    subtitle:
      " por favor completa los datos y te conectaremos con los servicios de",
    subtitle2: " PSE ",
    subtitle3: "para terminar el proceso.",
    info:
      "1. Si no eres un inversionista calificado podrás invertir hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.",
    info2:
      "2. Si ya invertiste en esta campaña y quieres aumentar el monto ve a - Mis inversiones - y selecciona la opción - Aumentar -",
    info3:
      "3. Podrás gestionar tu inversión de acuerdo a tus necesidades, a partir del día en que realizaste la transacción.",
    info4:
      "4. Ten en cuenta que no podrás realizar modificaciones o cancelaciones de tus inversiones:",
    info5: "i) el día del cierre por vencimiento de la campaña.",
    info6:
      "ii) en caso de cierre de la campaña por alcanzar el 100%, evento que puede ocurrir cualquier día durante la vigencia de la misma.",
    info7:
      "iii) durante el tiempo que se realice la adjudicación de la campaña.",
    info8:
      "Si ya invertiste en esta campaña y quieres aumentar el monto ve a - Mis inversiones - y selecciona la opción aumentar.",
    campaignToInvest: "Campaña",
    summary: "Resumen de la inversión",
    pay: "Pagar",
    loading: "Cargando",
    placeholder: {
      listOfBanks: "Selecciona el banco",
      documentType: "",
      documentNumber: "Número de identificación",
      dv: "DV"
    },
    validations: {
      bank: {
        required: "El banco es requerido"
      },
      documentType: {
        required: "El tipo de documento es requerido"
      },
      documentNumber: {
        required: "El numero de documento es requerido"
      },
      amount: {
        required: "El monto es requerido",
        min: "El monto es invalido",
        invalidAmount: "El monto es invalido"
      },
      dv: {
        min: "El valor minimo es 0",
        max: "El valor maximo es 9"
      },
      number: "El campo debe ser numérico"
    },
    increase: "El aumento de tu inversión",
    investment: "La inversión",
    descriptionPayInitMix:
      "{{type}} se hará de manera mixta, usando los servicios de ",
    descriptionPayEndMix:
      " para debitarlos de cuenta corriente o ahorros, y usando los recursos de tu disponible.",
    descriptionAvailable:
      "{{type}} se hará usando los recursos de tu disponible, si no tienes saldo podrás recargarlo directamente desde tu balance.",
    descriptionPayInit: "{{type}} se hará usando los servicios de ",
    descriptionPayApp: "PSE",
    descriptionPayEnd: " y se debitará de tu cuenta corriente o de ahorros."
  },
  investment: {
    investmentAmountTitle: "¿Cuánto vas a invertir?",
    investmentAmountText1: "indícanos el monto que quieres invertir en ",
    investmentAmountText2:
      "indícanos el monto que quieres aumentar en tu inversión a ",
    amountToInvestmentTitle: "Monto a invertir",
    walletUsed: "¿De dónde saldrá el dinero?",
    walletUsedSubtitle:
      "Utiliza el dinero de tu Disponible y/o realiza la transacción a través de un pago en línea",
    rechargeModalText:
      "Para poder invertir de manera más cómoda te recomendamos recargar tu cuenta para tener mayor control de lo que inviertes",
    rechargeText: "Recargar",
    availableBalance: "Disponible",
    pse: "Pago en línea",
    rechargeAccount: "Cargar",
    additionalAmount: "Monto adicional",
    amountPlaceHolder: "Ingrese un monto",
    continue: "Continuar",
    loading: "Cargando...",
    updateInvestment: "¿Cuánto quieres aumentar?",
    auxTextTitle: "Para que tengas la mejor experiencia no olvides que:",
    auxTextList1:
      "1. Si no eres un inversionista calificado podrás invertir hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.",
    auxTextList2:
      "2. Si ya invertiste en esta campaña y quieres aumentar el monto ve a “Mis inversiones” y selecciona la opción “Aumentar”.",
    auxTextList3: "3. Para más información consulta el botón de ayuda",
    auxTextList4:
      "Si ya tienes una inversión en esta ronda y quieres adquirir más paquetes ve a ",
    auxTextList5: "Mis inversiones ",
    auxTextList6: "y selecciona la opción aumentar.",
    auxTextList7:
      "Si no eres un inversionista calificado podrás invertir hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.",
    tooltip: {
      title: "Recuerda que:",
      message1_invest:
        "1. Podrás invertir en cualquier campaña de a2censo desde {{minimumAmount}} y en adelante en múltiplos de {{multipleAmount}}.",
      message1_increase:
        "1. Aumenta tu inversión desde {{minimumAmount}} y/o con valores múltiplos de {{multipleAmount}}",
      message2:
        "2. Si no eres un inversionista calificado podrás invertir hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.",
      message3:
        "Recuerda que no puedes ser el único inversionista de la campaña y tus inversiones deben ser menores a la meta."
    },
    tooltipToDoc: {
      title:
        "Eres inversionista calificado si cumples con al menos una de las siguientes opciones:",
      message1: "1. Tienes un patrimonio igual o mayor a 10.000 SMMLV.",
      message2:
        "2. Eres titular de un portafolio de inversión en valores, diferentes a los de financiación colaborativa, superior o igual a 5.000 SMMLV.",
      message3:
        "3. Eres profesional del mercado de valores como operador (Certificaciones AMV)",
      message4:
        "4. Eres una entidad vigilada por la Superintendencia Financiera de Colombia",
      message5:
        "5. Eres un organismo financiero extranjero o una multilateral.",
      quality:
        "Con la calidad tributaria podremos identificar a las personas y entidades que son contribuyentes, no contribuyentes y/o que declaran impuesto de renta."
    },
    validations: {
      amount: {
        invalid: "Monto mínimo de inversión {{ minimumValueFormatted }}",
        invalidIncrease: "Monto mínimo de aumento {{ minimumValueFormatted }}",
        invalidAmount: "Monto invalido",
        min: "Debe ingresar un monto",
        multipleAmount:
          "Tu inversión debe ser en múltiplos de {{ multipleAmount }}"
      },
      format: {
        invalid: "Este formato no es valido"
      },
      fileSize: {
        invalid: "El peso del archivo no es valido",
        required: "El archivo es requerido."
      },
      expirationDate: {
        invalid: "La fecha de expiración no puede ser menor que hoy."
      }
    },
    readyToInvest: "Listos para invertir",
    readyToRecharge: "Listos para recargar",
    readyToInvestSubtitle:
      "Déjanos conocer algunos datos adicionales para acompañarte en cada paso de tu inversión.",
    readyToRechargeSubtitle:
      "Déjanos conocer algunos datos adicionales para acompañarte en cada paso de tu recarga.",
    campaignToInvest: "Campaña a invertir: ",
    areYouQualifiedInvestor: "¿Eres inversionista calificado/calificada?",
    uploadDocumentTypeQuestion: "Elige una opción y carga tu documento",
    uploadDocumentLabel: "Subir documento",
    expirationDate: "Fecha de expiración",
    taxQualityQuestion: "Calidad tributaria",
    select: "Seleccionar",
    investmentsList: {
      title: "Mis inversiones",
      subtitle:
        "Acá podrás consultar el estado de tus inversiones, aumentarlas, cancelarlas o descargar un resumen de estas.",
      date: "Fecha de inversión:",
      dateCanceled: "Fecha de cancelación:",
      tooltipTitle:
        "A continuación se detalla los estados disponibles de tus inversiones:",
      tooltipText:
        "Podrás aumentar tu inversión inicial o cancelarla, siempre y cuando la campaña de tu interés continúe abierta.  \n  \n Recuerda: Cuando falte un día para el cierre de campaña no podrás cancelar o aumentar la inversión.",
      investTo: "Inversión a",
      yourInvestment: "Tu inversión",
      packagesAmount: "Paquetes de acciones adquiridos",
      sharesAmount: "Total de acciones adquiridas",
      collectedAmount: "Monto recaudado",
      investmentGoal: "Meta COP ",
      collected: "recaudado",
      cancelInvestment: "Cancelar inversión",
      pending: "Pendiente",
      active: "Aprobada",
      increase: "Aumentar",
      canceled: "Cancelada",
      rejected: "Rechazado",
      failed: "Fallida",
      exchange: "En canje",
      campaignState1: "Abierta",
      campaignState2: "Adjudicado",
      campaignState3: "Desierta",
      campaignState4: "Cerrada",
      campaignState5: "Cerrada",
      campaignState6: "Cerrada",
      campaignState7: "Cerrada",
      campaignState8: "Cerrada",
      campaignState9: "Cerrada",
      campaignState10: "Cerrada",
      campaignState11: "Borrador",
      campaignState12: "Cerrada",
      campaignState13: "Cerrada",
      campaignStateNext: "Próximamente",
      movements: "Mis movimientos",
      placeholderMessage: "Aún no has realizado ninguna inversión",
      placeholderLink: "Ir a Vitrina"
    },
    info:
      "1. Si no eres un inversionista calificado podrás invertir hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.",
    info2:
      "2. Si ya invertiste en esta campaña y quieres aumentar el monto ve a - Mis inversiones - y selecciona la opción - Aumentar -",
    info3: "3. Para más información consulta el botón de ayuda.",

    info4:
      "La inversión que vas a realizar es a largo plazo y de alto riesgo, en el peor de los casos podrías perder el total de tu inversión. ",
    info5: "¡No olvides diversificar!",
    keepinmind: "Ten en cuenta",
    dinamicCopiesInvestment: {
      copy1_new_2: "¿Cuánto vas a invertir?",
      copy2_new_2:
        "selecciona la cantidad de paquetes en los que quieres invertir en",
      copy1_new_3: "¿Cuánto vas a invertir?",
      copy2_new_3: "indícanos el monto que quieres invertir en ",
      copy1_update_2: "¿Cuánto quieres aumentar?",
      copy2_update_2:
        "selecciona la cantidad de paquetes que quieres aumentar en tu inversión a ",
      copy1_update_3: "¿Cuánto quieres aumentar?",
      copy2_update_3:
        "indícanos el monto que quieres aumentar en tu inversión a "
    }
  },
  investmentSucess: {
    title: "¡Gracias {{ name }}!",
    subtitle:
      "Tu inversión ha sido exitosa, te confirmaremos vía e-mail cuando la campaña logre su meta",
    transaction: "Estado de la transacción: "
  },
  transactionConfirmation: {
    sectionTitle: "¡Gracias, {{name}}!",
    sectionDescription:
      'Tu inversión está en proceso, consulta su estado ingresando a "Mi balance" - sección de "Mis inversiones"',
    sectionDescription2:
      'Tu aumento está en proceso, consulta su estado ingresando a "Mi balance" - sección de "Mis inversiones"',
    sectionDescription3:
      "Tu recarga está en proceso, consulta su estado ingresando a ",
    sectionDescription3End: ' sección de "Mis movimientos"',
    sectionDescription4:
      "Tu pago es muy importante para quienes creyeron en tu proyecto. Recuerda que podrás consultar tus movimientos en el historial de pagos.",
    title: "Estado de la transacción: En proceso",
    titleTimeout:
      "Estado de la transacción: Pendiente. Esto puede tardar unos minutos, para más información comunicate al (601) 313 9000",
    title2: {
      accepted: "Estado de la transacción: En canje",
      approved_by_bank: "Estado de la transacción: Aprobada",
      rejected: "Estado de la transacción: Rechazada",
      canceled: "Estado de la transacción: Cancelada",
      failed: "Estado de la transacción: Fallida"
    },
    description1: "CUS",
    description2: {
      investment: "Descripción: Inversión en {{campaignName}}",
      increase: "Descripción: Aumento en {{campaignName}}",
      recharge: "Descripción: Recarga de Mi Balance",
      due_payment: "Descripción: Pago de {{campaignName}}"
    },
    description3: "Valor:",
    description4: "Fecha de la transacción:",
    myBalance: "Mi balance",
    showcase: "Vitrina",
    history: "Historial",
    razonsocial: "BOLSA DE VALORES DE COLOMBIA S.A. - 830.085.426-1 ",
    bank: "Banco: {{bank}}",
    ticket: "TicketID: "
  },
  investmentCancellation: {
    inputError: "Formato invalido",
    accountError: "Cuenta Invalida",
    accountConfirmationError: "Confirmación de cuenta Invalida",
    continue: "Continuar",
    confirmAccount: "Confirmar en número de cuenta",
    accountNumber: "Número de cuenta",
    bank: "Banco",
    bankAccountType: "Tipo de cuenta",
    accountType: "Tipo de cuenta",
    selectYourBank: "Escoge tu banco",
    bankingData: "Datos bancarios",
    amountToInvest: "Monto a devolver",
    refundInformation: "Devolución de tu inversión",
    anyBank:
      "ser transparentes con tu dinero es lo más importante, indícanos los siguientes datos para hacer efectiva la devolución",
    available: "Disponible",
    newAvailable: "Se cargará a tu saldo disponible"
  },
  cancellationModal: {
    text: "Tu inversión hace la diferencia, respetamos tu decisión pero,",
    question: " ¿estás seguro que quieres cancelarla?",
    sure: "Estoy seguro"
  },
  cancellationModalSuccess: {
    title: " ¡Cancelación exitosa!",
    text:
      "tu cancelación ha sido exitosa. Para ver tu nuevo saldo disponible ingresa a ",
    link: "mi balance"
  },
  moneyWithdrawalModal: {
    text:
      "Los retiros de dinero que realices sólo se podrán realizar a cuentas bancarias que estén a nombre del titular de la inversión y no para terceros.",
    question: "¿Estás seguro de retirar tu dinero?",
    sure: "Estoy seguro"
  },
  identityValidationOtp: {
    title: "Valida tu identidad",
    messageemail: "Ingresa el código que hemos enviado a tu email.",
    message: "Ingresa el código que hemos enviado a tu número de teléfono.",
    noReceived: "No he recibido el código.",
    askAgain: "Solicitar de nuevo"
  },
  contactForm: {
    validations: {
      required: "Este campo es requerido",
      email: {
        required: "Este campo es requerido",
        invalidFormat: "Debes ingresar un correo electrónico válido"
      },
      phone: {
        invalidFormat: "Debes ingresar un número de teléfono válido"
      }
    },
    send: "Enviar",
    tab1Title: "Quiero más información",
    tab2Title: "Soy una PYME",
    tab2Subtitle: "¿Cómo me puedo financiar?"
  },
  hero: {
    back: "Volver",
    heading: {
      normal: "Cumple tus metas de crecimiento con",
      bold: "a2censo"
    },
    investors: "Inversionistas",
    investments: "Inversiones",
    millionsFinanced: "Millones financiados",
    succesfulCampaigns: "Campañas exitosas",
    header: {
      item1: "Nosotros",
      item2: "Comunidad",
      item3: "Iniciar Sesión",
      item4: "Crear cuenta"
    },
    tabs: {
      item1: "Invierte",
      item2: "Finánciate"
    }
  },
  campaignFilter: {
    total: "Total de campañas: ",
    placeholder: "Buscar Campaña...",
    filters: "Filtros",
    filterBy: "Filtrar por:",
    restoreBtn: "Restablecer",
    filterBtn: "Filtrar"
  },
  landing: {
    news: "Novedades",
    moreInfo: "Conócenos",
    showcase: "Campañas",
    crowdfunding: "Crowdfunding",
    becomeInvestor1: "¿Cómo convertirme ",
    becomeInvestor2: "en un inversionista?",
    whyChooseUs: {
      investTitle:
        "Te damos la opción de diversificar tu portafolio de inversión en el mismo lugar",
      investSubtitle:
        "*Nos gusta ser transparentes, por eso te contamos que se aplican las retenciones de ley ajenas a a2censo teniendo en cuenta si eres persona natural o jurídica, y acorde a tu calidad tributaria.",
      investDescription:
        "Puedes invertir en Deuda y Equity desde bajos montos y sin costo, teniendo la posibilidad de obtener rendimientos* o convertirte en accionista.",
      supervisionTitle: "Somos vigilados por la",
      supervisionBoldTitle: "Superintendencia Financiera.",
      supervisionDescription:
        "Somos la primer plataforma del país regulada bajo el decreto 1357 de 2018, que pone las reglas de la financiación colaborativa en Colombia. Consulta nuestra circular y reglamento en la sección legal.",
      fngSupportTitle:
        "El Fondo Nacional de Garantías respalda tu inversión en campañas de deuda",
      fngSupportDescription:
        "Mitigamos parte del riesgo de tu inversión gracias a nuestra alianza con el Fondo Nacional de Garantías. Consulta las condiciones de cada campaña de deuda para conocer su cobertura.",
      investorAboutUs1: "¿Qué dicen nuestros ",
      investorAboutUs2: "inversionistas?"
    }
  },
  qualifiedInvestor: {
    title1: "¡Perfecto!",
    title2: "¡Estamos listos!",
    subtitle1: "Su solicitud está siendo procesada.",
    subtitle2: "Su solicitud ha sido procesada correctamente.",
    know: "Regresar al inicio"
  },
  risk: {
    Visionary: "Visionario",
    Innovator: "Innovador",
    Sustainable: "Sostenible",
    Classic: "Clásico",
    Low: "Bajo"
  },
  validateCode: {
    oopsMessage:
      "Has superado el tiempo para la validación de tu correo, comunícate con nosotros para más información."
  },
  balance: {
    availableCash: "Disponible",
    loadBalance: "Cargar",
    withdrawals: "Retirar dinero"
  },
  myBalance: {
    subtitle:
      "Desde acá todos tus movimientos sin datos ocultos con un solo clic.",
    availableCash: "Saldo disponible: ",
    availableExchange: "Por confirmar ",
    investmentCash: "Invertido: ",
    investmentExchange: "Inversiones por confirmar ",
    investmentDebt: "Deuda ",
    investmentEquity: "Acciones y Notas Convertibles",
    tooltip:
      "Es el dinero que tienes disponible en tu Balance para seguir invirtiendo en los proyectos en a2censo."
  },
  moneyWithdrawal: {
    title: "Retiro de tu dinero",
    description:
      ", ser transparente con tu dinero es lo más importante, indícanos los siguientes datos para hacer efectivo el retiro de tu dinero disponible.",
    amount: "Monto a devolver",
    personType: "Tipo de persona",
    bankInfo: "Datos bancarios",
    bankNumber: "Número de cuenta",
    bankName: "Escoge el banco",
    bankAccountType: "Tipo de cuenta",
    saveAndContinue: "Continuar",
    confirmBankNumber: "Confirma número de cuenta",
    companyName: "Razón social",
    names: "Nombres",
    firstLastName: "Primer Apellido",
    secondLastName: "Segundo Apellido",
    documentType: "typo de documento",
    documentNumber: "Número de identificación",
    confirmDocumentNumber: "Confirma número de identificación",
    accountHolder: "Nombre del titular de la cuenta",
    middleText:
      "Los retiros de dinero que realices sólo se podrán realizar a cuentas bancarias que estén a nombre del titular de la inversión y no para terceros.",
    validations: {
      number: "Debe ser un valor numérico",
      amount: {
        invalidAmount:
          "El monto debe ser mayor a cero(0) y no puede superar tu saldo disponible"
      },
      bank: {
        required: "El campo banco es obligatorio."
      },
      bankAccountType: {
        required: "El campo tipo de cuenta es obligatorio."
      },
      bankNumber: {
        required: "El número de cuenta es obligatorio.",
        min: "El número de cuenta es de mínimo 6 digitos",
        max: "El número de cuenta es de máximo 30 digitos",
        minMax: "Debe ser un valor numérico de mínimo 6 y máximo 30 digitos"
      },
      confirmBankNumber: {
        required: "Confirmar el número de cuenta es obligatorio",
        oneOf: "Los números de cuenta deben coincidir",
        min: "El número de cuenta es de mínimo 6 digitos",
        max: "El número de cuenta es de máximo 30 digitos",
        minMax: "Debe ser un valor numérico de mínimo 6 y máximo 30 digitos"
      },
      names: {
        required: "El campo Nombre es obligatorio"
      },
      firstLastName: {
        required: "El campo Primer Apellido es obligatorio"
      },
      secondLastName: {
        required: "El campo Segundo Apellido es obligatorio"
      },
      documentType: {
        required: "El campo tipo de documento es obligatorio"
      },
      documentNumber: {
        required: "El campo número de documento es obligatorio"
      },
      confirmDocumentNumber: {
        oneOf: "Los números de documento deben coincidir",
        required: "El campo confirma número de documento es obligatorio"
      }
    },
    footer:
      "Recuerda que el proceso de retiro de recursos de a2censo puede tardar hasta 2 días hábiles en verse reflejado en tu cuenta bancaria.",
    descriptionFooter1: "Las transacciones que realices",
    descriptionFooter2: " antes de las 3:00 pm ",
    descriptionFooter3:
      "serán procesadas el mismo día, sin embargo todas las transacciones realizadas fuera de este horario serán procesadas el siguiente día hábil."
  },
  novelties: {
    error: "Pronto regresaremos con todas nuestras novedades"
  },
  recharge: {
    title: "¿Cuánto vas a recargar?",
    subTitle: ", indícanos el monto que quieres cargar a tu disponible.",
    amountToRechargeTitle: "Monto a recargar",
    amountPlaceHolder: "Ingrese un monto",
    tooltip: {
      title: "Recuerda que:",
      message1:
        "Recuerda que si no eres un inversionista calificado, no puedes recargar un monto superior al 20% de tus ingresos o tu patrimonio, el que resulte mayor.",
      message2: `1. El monto que cargues en este proceso se reflejará en el disponible de tu balance para que puedas gestionar tus inversiones de acuerdo a tus necesidades. \n
        2. Si eres un inversionista no calificado podrás tener en tu balance (Suma de mi disponible y mis inversiones) el valor correspondiente hasta el 20% de tus ingresos anuales o patrimonio, lo que resulte mayor.`
    },
    continue: "Continuar",
    loading: "Cargando",
    validations: {
      amount: {
        required: "El monto es requerido",
        invalidAmount: "Monto inválido",
        invalidAmount2:
          "El monto a recargar no puede superar tu patrimonio o ingresos",
        min: "Debe ingresar un monto"
      }
    }
  },
  inputAmount: {
    error: "El monto es invalido"
  },
  rechargeSummary: {
    oneStepAhead: "Un paso más cerca",
    subtitle:
      ", por favor completa los datos y te conectaremos con los servicios de PSE para terminar el proceso.",
    amountToRecharge: "Monto a cargar",
    descriptionPayInit: "La recarga se hará usando los servicios de ",
    descriptionPayApp: "PSE",
    descriptionPayEnd: " y se debitará de tu cuenta corriente o de ahorros",
    recharge: "Cargar",
    loading: "Cargando",
    validations: {
      bank: {
        required: "El banco es requerido"
      },
      documentType: {
        required: "El tipo de documento es requerido"
      },
      documentNumber: {
        required: "El numero de documento es requerido"
      },
      amount: {
        required: "El monto es requerido",
        min: "El monto es invalido",
        invalidAmount: "El monto es invalido"
      },
      dv: {
        min: "El valor minimo es 0",
        max: "El valor maximo es 9"
      },
      number: "El campo debe ser numérico"
    },
    placeholder: {
      documentType: "",
      dv: "DV",
      listOfBanks: "Selecciona el banco",
      documentNumber: "Número de identificación"
    }
  },
  moneyWithdrawalConfirmation: {
    sectionTitle: "Retiro en proceso",
    sectionDescription:
      ", hemos recibido tu solicitud de retiro, en un plazo no mayor a 3 días hábiles lo verás reflejado en tu cuenta bancaria.",
    title: "Estado de la transacción: En proceso",
    description1: "CUS",
    description2: "Descripción: Retiro a Cta.",
    descriptionx:
      "Valor retirado: {{retirado}} - Retención 4X1000: {{retencion}}",
    description3: "Valor retirado: {{retirado}}",
    description4: "Retención 4X1000: {{retencion}}",
    description5: "Total a devolver:",
    description6: "Fecha de la solicitud:",
    continue: "Mi balance",
    bankNanme: "Empresa: ",
    states: {
      pending: "Pendiente",
      pending_to_disperse: "Pendiente por dispersar",
      rejected: "Rechazada",
      approved: "Aprobado"
    },
    accountTypes: {
      savings: "Ahorros",
      regular: "Corriente"
    }
  },
  codebtorErrorCodes: {
    CODEBTORS_004:
      "Tu codeudor socio es colaborador bvc, por lo tanto no puedes continuar con la solicitud.",
    CODEBTORS_003:
      "Tu codeudor socio es accionista bvc, por lo tanto no puedes continuar con la solicitud.",
    CODEBTORS_001:
      "Estamos teniendo inconvenientes y no podemos continuar con tu solicitud. "
  },
  nameAlert: {
    text:
      "¡Lo sentimos! Los siguientes codeudores no son válidos, por favor ingresa uno nuevo."
  },
  thankYou: {
    title: "¡Gracias!",
    subtitle: "Transacción exitosa",
    pymeSubtitle:
      "Tu pago es muy importante para quienes creyeron en tu proyecto.",
    paragraph:
      'En un lapso de 30 minutos podrás ver desde "Mis inversiones" el resultado de este movimiento.',
    pymeParagraph:
      "Recuerda que podrás consultar tus movimientos en el historial de pagos.",
    myBalance: "Mi balance",
    continue: "Continuar",
    history: "Historial"
  },
  myMovements: {
    date: "Fecha: ",
    byCampaign: "Inversión campaña",
    byRetirement: "Retiro",
    byRecharge: "Recarga",
    byPayCapital: "Pago capital campaña",
    byPayPerformance: "Pago rendimiento campaña",
    byCancellation: "Cancelación",
    byCashback: "Devolución",
    byIncrease: "Incremento campaña",
    byRetention: "Retención",
    retirement: {
      approved_by_bank: "Retiro desde Mi Disponible",
      accepted: "Retiro desde Mi Disponible",
      pending: "Retiro desde Mi Disponible",
      rejected: "Retiro desde Mi Disponible"
    },
    retention: {
      "7x100": {
        approved_by_bank: "Retención tributaria sobre rendimientos financieros",
        accepted: "Retención tributaria sobre rendimientos financieros",
        pending: "Retención tributaria sobre rendimientos financieros",
        rejected: "Retención tributaria sobre rendimientos financieros"
      },
      "4x1000": {
        approved_by_bank: "Pago tributario por retiro de fondos",
        accepted: "Pago tributario por retiro de fondos",
        pending: "Pago tributario por retiro de fondos",
        rejected: "Pago tributario por retiro de fondos"
      }
    },
    retentionConcept: {
      "7x100": "Pago sobre la campaña ",
      "4x1000": "Retiro de dinero"
    },
    increase: {
      available: {
        approved_by_bank: "Aumento desde Mi Disponible",
        accepted: "Aumento desde Mi Disponible",
        pending: "Aumento desde Mi Disponible",
        rejected: "Aumento desde Mi Disponible",
        failed: "Aumento desde Mi Disponible"
      },
      pse: {
        approved_by_bank: "Aumento desde PSE",
        accepted: "Aumento desde PSE",
        pending: "Aumento desde PSE",
        rejected: "Aumento desde PSE",
        failed: "Aumento desde PSE"
      }
    },
    investment: {
      available: {
        approved_by_bank: "Inversión desde Mi Disponible",
        accepted: "Inversión desde Mi Disponible",
        pending: "Inversión desde Mi Disponible",
        failed: "Inversión desde Mi Disponible",
        rejected: "Inversión desde Mi Disponible"
      },
      pse: {
        approved_by_bank: "Inversión desde PSE",
        accepted: "Inversión desde PSE",
        pending: "Inversión desde PSE",
        rejected: "Inversión desde PSE",
        failed: "Inversión desde PSE"
      }
    },
    recharge: {
      approved_by_bank: "Recarga de Mi disponible desde PSE",
      accepted: "Recarga de Mi disponible desde PSE",
      pending: "Recarga de Mi disponible desde PSE",
      rejected: "Recarga de Mi disponible desde PSE",
      failed: "Recarga de Mi disponible desde PSE"
    },
    cancellation: {
      approved_by_bank: "Cancelación de inversión",
      accepted: "Cancelación de inversión",
      pending: "Cancelación de inversión",
      rejected: "Cancelación de inversión"
    },
    capital: {
      approved_by_bank: "Abono a capital",
      accepted: "Abono a capital",
      pending: "Abono a capital",
      rejected: "Abono a capital"
    },
    cashback: {
      "over-demand": {
        accepted: "Devolución de inversión por campaña sobre demandada",
        approved_by_bank: "Devolución de inversión por campaña con sobredemanda"
      },
      unawarded: {
        approved_by_bank: "Inversión no adjudicada",
        accepted: "Devolución de inversión por campaña desierta",
        pending: "Inversión no adjudicada",
        rejected: "Inversión no adjudicada"
      },
      campaign: {
        approved_by_bank: "Devolución de inversión",
        accepted: "Devolución de inversión",
        pending: "Devolución de inversión",
        rejected: "Devolución de inversión"
      }
    },
    earnings: {
      approved_by_bank: "Pago de rendimientos financieros",
      accepted: "Pago de rendimientos financieros",
      pending: "Pago de rendimientos financieros",
      rejected: "Pago de rendimientos financieros"
    },
    campaignConcept: "Sobre la campaña ",
    search: "Buscar",
    keyWord: "Palabra clave",
    fromDate: "Desde",
    toDate: "Hasta",
    states: {
      cancellation: {
        pending: " (Pendiente)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      },
      capital: {
        pending: " (Pendiente)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      },
      cashback: {
        pending: " (Pendiente)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      },
      earnings: {
        pending: " (Pendiente)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      },
      increase: {
        pending: " (Pendiente)",
        accepted: " (En canje)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)",
        failed: " (Fallida)"
      },
      investment: {
        pending: " (Pendiente)",
        accepted: " (En canje)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)",
        failed: " (Fallida)"
      },
      recharge: {
        pending: " (Pendiente)",
        accepted: " (En canje)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)",
        failed: " (Fallida)"
      },
      retention: {
        pending: " (Pendiente)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      },
      retirement: {
        pending: " (En proceso)",
        accepted: " (Aceptada)",
        approved_by_bank: " (Aprobada)",
        rejected: " (Rechazada)"
      }
    },
    clean: "Limpiar filtros",
    searchPlaceholder: {
      title: "No hemos encontrado resultados",
      subtitle: "Ajusta los filtros para mejores resultados."
    }
  },
  paginator: {
    pageOf: "paginas de"
  },
  registerProcess: {
    registrationNeeded:
      "Vincúlate en a2censo para que puedas invertir en las campañas disponibles.",
    goToRegistrationProcess: "¡Vincularme!"
  },
  movements: {
    title: "Mis movimientos",
    loading: "cargando..."
  },
  paymentsHistory: {
    pageTitle: "Historial de pagos",
    byCampaign: "Por campaña",
    byMonth: "Por mes",
    byState: "Por estado",
    searchButton: "Buscar",
    cleanFilters: "Limpiar filtros",
    loading: "Cargando lista de pagos",
    noPayments: "Aún no tienes pagos.",
    paid: "Pagado",
    rejected: "Rechazado",
    approved_by_timeout: "Aprobada por tiempo",
    exchange: "En canje",
    inactive: "Inactiva",
    issuer_approved: "Pago aprobado",
    late_payment: "Pago en mora",
    paid_by_collection_house: "Pago por casa de cobranza",
    pending_approve: "Pendiente de aprobación",
    pending_new_amount_request: "Solicitud de nuevo monto pendiente",
    pending_reject_request: "Solicitud de rechazo pendiente"
  },
  accountRecovery: {
    title: "Reactivar cuenta",
    subtitle:
      "Te enviaremos un código para validar tu identidad. Indicanos en dónde quieres recibirlo.",
    sms: "Vía SMS",
    email: "Vía email",
    emailSubtitleInit: "Ingresa el correo que registraste en ",
    emailSubtitleBold: "a2censo",
    emailSubtitleEnd: " para poder continuar.",
    loginButton: "Iniciar sesión",
    passwordRecovery: "Recuperar mi contraseña",
    continueButton: "Continuar",
    cancelLink: "Cancelar",
    suspendedModalTitle: "Tu cuenta está suspendida",
    suspendedModalText:
      "Permítenos acompañarte en el proceso para volver a acceder y para mantener tu cuenta segura.",
    recoverySuccessTitle: "¡Tu cuenta está lista!",
    recoverySuccessText:
      "Ingresa con tus credenciales y explora todas nuestras campañas.",
    userNotExistsTitle: "Usuario no registrado",
    userNotExistsText:
      "Por favor, verifica que el correo ingresado sea el mismo con el que te registraste en a2censo."
  },
  prepaidDestiny: {
    title: "¿Qué quieres hacer con tu abono?",
    subTitle:
      ", tu abono podrás dirigirlo a dos fines específicos, por favor indicanos el que mejor se adapte a tus necesidades.",
    reduceQuota: {
      title: "Reducir la cantidad de cuotas",
      description:
        "Dependiendo del total de tu abono re-calcularemos las cuotas pactadas inicialmente y notificaremos a tus inversionistas."
    },
    reduceTime: {
      title: "Reducir el valor de pago",
      description:
        "Tu abono se realizará con el fin de ajustar el valor de tu pago mínimo para las siguientes cuotas."
    },
    continue: "Continuar",
    loading: "Cargando...",
    errorByTime: {
      title: "Error en solicitud"
    },
    fileName: "derechos patrimoniales",
    modal: {
      text1: "Has seleccionado ",
      text2: ". Recuerda que al continuar no podrás cambiar esta opción."
    }
  },
  timeLine: {
    timeToPay: "Te quedan {{ day }} {{ days }} para pagar",
    deadLine: "Es tu último día de pago",
    deadLineExtended: "Podrás hacer tu pago el próximo día hábil",
    day: "día",
    timeFinished: "Tu tiempo para pagar a finalizado",
    daysText: "días",
    dayText: "día"
  },
  payment: {
    minimumAmount: "Pago mínimo",
    minimumAmountText:
      "Hace referencia al monto mínimo que debes pagar según lo adjudicado.",
    paymentValueText: "Valor a pagar en COP",
    otherAmount: "Otro monto",
    otherAmountText:
      "Acá podrás abonar un monto mayor al pago mínimo de tu deuda.",
    date: "Fecha límite de pago: "
  },
  requestSendModal: {
    title: "¡Solicitud enviada!",
    description:
      ", tu solicitud ha sido recibida. Para ver tu el estado de tu solicitud ingresa a ",
    myPayments: "Mis pagos"
  },
  errorCodes: {
    "02":
      "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento",
    "EXPERIAN-000":
      "¡Lo sentimos! Estamos teniendo inconvenientes para validar tu documento. Inténtalo más tarde.",
    "04":
      "Hemos identificado un inconveniente tu documento de identidad. Comunicate con nosotros para más información.",
    "05":
      "Hemos identificado un inconveniente tu documento de identidad. Comunicate con nosotros para más información.",
    "06":
      "Hemos identificado un inconveniente con tu datos. Comunicate con nosotros para más información.",
    "09":
      "Hemos identificado un inconveniente con tu datos. Comunicate con nosotros para más información.",
    10: "Hemos identificado un inconveniente con tu datos. Comunicate con nosotros para más información.",
    17: "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento",
    "036":
      "Estamos teniendo inconvenientes con los datos, intenta en otro momento",
    "037":
      "Estamos teniendo inconvenientes con la configuración, intenta en otro momento",
    "038":
      "Estamos teniendo inconvenientes para guardar la información, intenta en otro momento",
    "039":
      "No terminaste tu vinculación. Intenta nuevamente y permítenos guiarte en cada paso.",
    "041":
      "Estamos teniendo inconvenientes para esta transacción, intenta en otro momento",
    "042":
      "Estamos teniendo inconvenientes para consultar tu transacción. Comunicate con nosotros para más información.",
    "043":
      "Estamos teniendo inconvenientes para consultar tus transacciones. Comunicate con nosotros para más información.",
    "044": "No se ha podido crear la transacción. Inténtalo más tarde.",
    "045":
      "Estamos teniendo inconvenientes con la sesión. Comunicate con nosotros para más información.",
    "046":
      "Estamos teniendo inconvenientes para iniciar tu sesión. Comunicate con nosotros para más información.",
    "048":
      "Estamos teniendo inconvenientes para cancelar tu inversión. Comunicate con nosotros para más información.",
    "049": "Aún no puedes cancelar tu inversión. Intenta más tarde.",
    "INVESTMENT-050":
      "Estamos teniendo inconvenientes para procesar tu inversión. Comunicate con nosotros para más información.",
    "050":
      "Estamos teniendo inconvenientes para procesar tu inversión. Comunicate con nosotros para más información.",
    "051":
      "Estamos teniendo inconvenientes para procesar tu inversión. Comunicate con nosotros para más información.",
    "052": "Esta campaña ya cerró. Explora nuestra vitrina para más opciones.",
    "053":
      "En este momento no puedes eliminar la información del socio. Comunicate con nosotros para ayudarte.",
    "054": "No puedes cancelar tu inversión el último día de campaña.",
    "060": "Ya tienes una inversión en esta campaña. Auméntala.",
    55: "Has superado el tiempo límite para finalizar tu registro, por favor inicia nuevamente.",
    "056":
      "Estamos teniendo inconvenientes con la creación de tu cuenta. Comunicate con nosotros para más información.",
    "057":
      "Estamos teniendo inconvenientes para validar tu correo. Comunicate con nosotros para más información.",
    "058": "Ha ocurrido un error inesperado. Intentalo más tarde",
    "059":
      "No puedes aumentar tu inversión. Comunicate con nosotros para más información.",
    "004": "¡Lo sentimos! Los datos ingresados no coinciden.",
    "006": "Tu cuenta no existe. ¡Regístrate ahora!",
    "010":
      "Aún falta un poco más para que puedas ingresar. Si tienes dudas, comunícate con nosotros.",
    "010-1":
      "Has gastado todos tus intentos. Por favor vuelve a intentarlo en 24 horas.",
    "011": "Tu cuenta está bloqueada. Por favor, comunícate con nosotros.",
    "033":
      "Aún no has verificado tu email ó número telefónico, por favor hazlo para permitirte continuar con tu proceso de integración.",
    34: "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento",
    35: "¡Lo sentimos! Estamos teniendo inconvenientes, por favor intenta en otro momento",
    416: "Recuerda que por ser colaborador bvc solo podrás ser parte de a2censo invitando a familiares, amigos o conocidos.",
    423: "Tu inversión debe ser mayor a {{ amount }} pesos",
    424: "Tu inversión debe ser en múltiplos de {{ amount }} pesos",
    425: "Si eres el último inversionista recuerda que solo te adjudicamos lo que hace falta para cerrar la campaña y el excedente será devuelto a tu disponible",
    426: "Tu inversión debe ser en múltiplos de $50.000 pesos",
    427: "Como eres el primero, tu inversión debe ser menor a la meta.",
    428: "Remanente de la campaña menor al monto mínimo",
    429: "Recuerda que no puedes superar el 20% de tus ingresos o patrimonio, lo que resulte mayor.",
    430: "El inversionista ya tiene una inversión en la campaña seleccionada.",
    431: "No se puede aumentar la inversión debido a que no está en estado de Aprobado",
    432: "El inversionista selecciona el monto total a financiar.",
    433: "Tu inversión debe ser menor a la meta.",
    434: "Recuerda que en Mi Balance no puedes superar el 20% de patrimonio o ingresos anuales, el que sea mayor",
    20: "Gracias por esperarnos. En un momento cargaremos la información.",
    21: "Al parecer algo salió mal con tus respuestas. Por favor intenta nuevamente.",
    22: "Si quieres consultar los datos ingresados en la vinculación, por favor contáctanos",
    23: "No podemos validar la información del representante legal. Por favor, comunícate con nosotros.",
    25: "No podemos validar la información del socio. Por favor, comunícate con nosotros.",
    26: "Estamos teniendo inconvenientes para confirmar tu información financiera. Por favor intentalo en otro momento.",
    27: "No pudimos guardar tu información, por favor ingrésala nuevamente para continuar.",
    "027": "El correo que estás registrando no existe.",
    1002: "Recuerda que por ser accionista bvc sólo podrás ser parte de a2censo invitando a familiares, amigos o conocidos.",
    1003: "Recuerda que no puedes ser parte de a2censo siendo accionista asociado de la BVC.",
    "055":
      "El CIIU ingresado pertenece a los sectores restringidos para participar en a2censo",
    500: "Estamos teniendo inconvenientes con la información y algunos de nuestros servicios",
    "2003": "¡Lo sentimos! Los datos ingresados no coinciden.",
    "INVESTMENT-051": "Esta campaña aun no esta abierta para recibir inversiones",
    "ERR_NO_USR": "Tu cuenta no existe.",
    "ERR_BLK": "Tu cuenta no esta suspendida.",
    "ERR_NO_SUSP": "Tu cuenta no esta suspendida."
  },
  inputFile: {
    text: "Subir documento"
  },
  myPayments: {
    title: "Mis pagos",
    subtitle:
      ", acá podrás hacer el pago de tu deuda de manera ágil, explorando diferentes opciones de acuerdo a tus necesidades. Recuerda hacer tus pagos dentro de los tiempos estipulados para evitar caer en mora. ",
    link: "Historial de Pagos.",
    campaign: "Campaña ",
    campaign2: "Campaña: ",
    noPendingPayments: "¡No tienes pagos pendientes!",
    notifyEmail:
      "Te informaremos vía e-mail cuando tu próximo pago esté listo.",
    button: "Pagar",
    warningIcon: "!",
    warning: "Pago en mora",
    warning2: "Sobrepasaste la fecha límite de pago",
    warning3:
      "A partir de este momento se ejecutará el proceso de cobranza comercial directamente con Covinoc de acuerdo a lo estipulado en nuestra circular.",
    warning4: "!No tienes pagos pendientes!",
    warning5: "Todavía no se tienen detalles del pago",
    warning6: "Te informaremos vía e-mail cuando tu \npróximo pago esté listo.",
    validations: {
      amount: {
        invalid: "Monto inválido"
      }
    },
    issuerPayment: {
      approved_by_timeout: "Pendiente pago",
      exchange: "En canje",
      inactive: "Inactive",
      issuer_approved: "Pendiente pago",
      late_payment: "Pago en mora",
      paid: "Pago exitoso",
      paid_by_collection_house: "Pago por COVINOC",
      pending_approve: "Pendiente aprobación {{name}}",
      pending_new_amount_request: "Pendiente aprobación solicitud otro monto",
      pending_reject_request: "Pendiente aprobación solicitud de rechazo",
      rejected: "Pago rechazado"
    }
  },
  paymentDetail: {
    title: "Aprobación",
    subtitle:
      ", acá encontrarás el detalle total de tu pago y las retenciones para tu aprobación.",
    text:
      "Recuerda que el plazo máximo de aprobación será hasta dos días antes de la fecha límite, si para esa fecha no has realizado tu aprobación, se entenderá que estás de acuerdo con la información.",
    totalPaymentText: "Pago total",
    fileName: "derecho patrimonial.xlx",
    subtitle2: "Reporte liquidación de derechos patrimoniales",
    buttonApprove: "Aprobar",
    buttonReject: "Rechazar",
    numReference: "# de referencia {{numReference}}",
    types: {
      bvc_commission: "Comisión bvc (+IVA)",
      less_retentions: "Valor de retención",
      plus_capital: "Pago por capital",
      plus_interest: "Pago por intereses",
      fng_commission: "Pago comisión de fng"
    },
    modal: {
      text1:
        "Estas a un clic de aprobar. Recuerda que al continuar no podrás deshacer esta confirmación"
    }
  },
  downloadFile: {
    buttonName: "Descargar"
  },
  linkCard: {
    buttonName: "Descargar",
    seeMore: "Ver más"
  },
  errorModal: {
    title: "¡Lo sentimos!",
    oops: "Oops :(",
    success: "Ok",
    continue: "Continuar",
    goHome: "Regresar al inicio",
    tryLater: "Por favor, vuelve a intentarlo más tarde."
  },
  investor: {
    title: "Reporte liquidación de derechos patrimoniales",
    investorTitle: "Inversionista",
    operation: "Cap. + int.s - retención",
    qualityTax: "Calidad tributaria",
    paymentByCapital: "Pago por Cap.",
    nit: "NIT.",
    paymentByInterest: "Pago por intereses",
    accountNumber: "Número de cuenta",
    retentionValue: "Valor de retención",
    retentionPercentage: "% de retención",
    retentionBase: "Base retención",
    valueToPay: "Valor a pagar",
    total: "Total",
    plusSymbol: "+",
    restSymbol: "-",
    percentageSymbol: "%",
    qualityTaxType: {
      no_quality: "Sin calidad tributaria",
      great_contributor: "Gran contribuyente",
      no_contributor: "No contribuyente",
      self_retainer: "Autorretenedor",
      declarant: "Declarante",
      no_declarant: "No declarante"
    },
    accountType: {
      ahorros: "Cuenta de ahorros",
      corriente: "Cuenta corriente"
    }
  },
  confirmPayAmount: {
    title: "Confirmacion de datos",
    description:
      ", si estás de acuerdo con los datos de tu pago te conectaremos con los servicios de ",
    description2: "PSE",
    description3: " para terminar el proceso",
    titleSection: "Pago mínimo",
    continue: "Continuar",
    loading: "Cargando...",
    descriptionPayInit: "El pago se hará usando los servicios de ",
    descriptionPayApp: "PSE",
    descriptionPayEnd: " y se debitará de tu cuenta corriente o de ahorros.",
    validations: {
      bank: {
        required: "El banco es requerido"
      },
      documentType: {
        required: "El tipo de documento es requerido"
      },
      documentNumber: {
        required: "El numero de documento es requerido"
      },
      dv: {
        min: "El valor minimo es 0",
        max: "El valor maximo es 9"
      },
      number: "El campo debe ser numérico"
    },
    placeholder: {
      documentType: "",
      dv: "DV",
      listOfBanks: "Selecciona el banco",
      documentNumber: "Número de identificación"
    }
  },
  paymentHistoryCard: {
    due: "# Cuota",
    date: "Fecha: ",
    minimumAmount: "Pago mínimo",
    paidTotalAmount: "Valor total pagado",
    plus_capital: "Pago por capital",
    plus_interest: "Pago por intereses",
    less_retentions: "Valor de retención",
    bvc_commission: "Pago comisión de bvc",
    fng_commission: "Pago comisión del FNG",
    sharpReferenceNumber: "# de referencia",
    successFee: "Cuota de éxito",
    algo: "Otros pagos",
    stringReferenceNumber: "Número de referencia",
    paid: "Pagado",
    approved: "Pagado",
    rejected: "Rechazado",
    approved_by_timeout: "Aprobada por tiempo limite",
    exchange: "En canje",
    inactive: "Inactiva",
    issuer_approved: "Pago aprobado",
    late_payment: "Pago en mora",
    paid_by_collection_house: "Pago por casa de cobranza",
    pending_approve: "Pendiente de aprobación",
    pending_new_amount_request: "Solicitud de nuevo monto pendiente",
    pending_reject_request: "Solicitud de rechazo pendiente"
  },
  rejectRequestRejection: {
    title: "Solicitud completada satisfactoriamente.",
    subtitle: "Se actualizo el estado del pago del emisor."
  },
  simulatorNotAvailable: {
    title: "¡Nos estamos actualizando!",
    subtitle:
      "Pronto podrás simular tu inversión incluyendo todas las condiciones estipuladas en la campaña.",
    buttom: "Entendido"
  },
  WelcomeToPYME: {
    hi: "Hola,",
    Welcome: " ¡Bienvenido a la comunidad de a2censo!",
    cardGuide: {
      areYouNew: "¿Eres nuevo en",
      ownCommunity: "nuestra comunidad?",
      description1:
        "Pronto nuestro equipo comercial te contactará para acompañarte en el proceso y resolver todas tus dudas. ",
      description2: {
        part1: "Descarga nuestra",
        part2: " guía con recomendaciones para lanzar una campaña exitosa"
      },
      description3: {
        part1:
          "Explora nuestra vitrina para conocer cómo otros empresarios han estructurado sus campañas y",
        part2: " hoy están un paso más cerca de poner en marcha sus proyectos."
      }
    },
    cardWelcome: {
      title: {
        part1: "¡Bienvenido",
        part2: " empresario a2censo!"
      },
      description:
        "Tú crecimiento y tus proyectos empresariales son lo más importante dentro de nuestra comunidad.",
      financedYourself: "¿Ya te financiaste en a2censo?",
      showCase: "Vitrina",
      myPayments: "Mis pagos"
    },
    footer: {
      description: "Si necesitas ayuda ",
      descriptionBold: "no dudes en contactarnos:",
      phone: "(601) {{phone}}",
      mail: "a2censo@bvc.com.co"
    }
  },
  patrimonialRights: {
    send: "Enviar",
    loading: "Cargando...",
    title: "¿Cómo podemos ayudarte?",
    subtitle:
      "Déjanos saber las razones por las cuales rechazaste tu pago y las retenciones para que nuestro equipo valide tu caso.",
    fieldPlaceholder: "Escribe aquí tu comentario...",
    excelFile: "Archivo excel",
    wordFile: "Archivo word",
    imageFile: "Archivo imágen",
    pdfFile: "Archivo pdf",
    patrimonialRight: "derecho patrimonial.xlx",
    myPayments: "Mis pagos",
    receivedDocument: "¡Solicitud recibida!",
    receivedDocumentText1:
      "Nuestro equipo se pondrá en contacto para dar respuesta a tu caso, así podrás gestionar tu pago dentro de los tiempos establecidos.",
    receivedDocumentText2: "Si deseas aún puedes hacer el pago de ",
    minAmount: "monto mínimo.",
    errors: {
      requiredField: "Este campo es obligatorio",
      requiredFile: "Debes subir el archivo con la razón de tu corrección."
    },
    validations: {
      invalid:
        "El formato del archivo no esta admitido, usar PDF, Word, Excel, JPEG o PNG"
    },
    financeSection: {
      howFinancial: "¿Cómo financiarte?",
      beBusiness: "Ser empresario a2censo"
    }
  },
  shareholdersLabel: {
    debt: "Inversionistas",
    shares: "Accionistas",
    convertibleNote: "Inversionistas"
  },
  howPayme: {
    title: "¿Cómo me",
    titleBold: " pagarán?",
    footerTitle: "Para tener",
    footerTitleBold: " en cuenta",
    simulator: "Simula tu inversión",
    descriptionFooter:
      "Recuerda que toda inversión implica un riesgo, para mitigarlo puedes diversficar tu dinero en varias campañas que a su vez cuentan con un respaldo parcial del Fondo nacional de garantías en cada campaña."
  },
  sliderCampaings: {
    campaings: "Campañas",
    lastCampaings: "Últimas campañas",
    featured: " destacadas",
    successfull: " exitosas",
    titles: {
      collected: "Recaudado",
      collectedAmount: "Monto recaudado",
      investments: "Inversionistas",
      shareholders: "Accionistas",
      days: "Días para cerrar",
      goal: "Meta",
      goalAccomplished: "Meta cumplida"
    }
  },
  InvestTab: {
    tab1: "Vitrina",
    tab2: "Ser Inversionista",
    tab3: "¿Por qué elegirnos?"
  },
  financeSection: {
    goal: "Meta",
    goalAccomplished: "Meta cumplida",
    howFinancial: "¿Cómo financiarte?",
    beBusiness: "Ser empresario a2censo",
    neededsTitle: "¿Qué necesitas para ",
    neededsTitleFinance: "financiarte en a2censo?",
    neededsDescription:
      "Si estás interesado en hacer parte de a2censo tu compañía debe cumplir los siguientes requisitos:",
    neededsColombianPeople: "Ser persona jurídica constituida en Colombia.",
    neededsCountWithMonths:
      "No estar en proceso de liquidación, acuerdo de reorganización empresarial u otra causal específica similar.",
    neededsMillions: "Tener ingresos anuales superiores a 900 millones COP.",
    neededsNotRestringedSectors:
      "No pertenecer a sectores económicos restringidos.",
    needsOneYearAtMarket:
      "Contar con al menos un año de operación y de ventas.",
    tootltip: {
      title: "Algunos sectores económicos por ahora no podrán participar, ",
      bold: "están restringidos:",
      needsHelp:
        "\nMovimientos políticos, juegos de azar, Casas de cambio, Cooperativas, Originadores de Crédito, Intermediación financiera, Comunidades religiosas, Agricultura, Ganadería, Caza, Silvicultura y pesca, Explotación de minas y canteras, Administración pública y defensa, Planes de seguridad social de afiliación obligatoria, Actividades de organizaciones extraterritoriales (incluyendo ONG's), Construcción de obras civiles o vivienda."
    },
    howToPaymentWork: {
      howTo: "¿Cómo funcionan los",
      paymentIn: "pagos en a2censo?"
    },
    why: "¿Por qué ",
    whyFinancial: "financiarte en a2censo?",
    whyDescription:
      "Si eres una empresa colombiana, tendrás la oportunidad de financiarte a través del mercado de valores de La Bolsa de Valores de Colombia, y encontrar beneficios adicionales para el crecimiento de tu compañía:",
    define: "Defines tus ",
    defineYourConditions: "condiciones de financiación.",
    financialYour: "Financias tus ",
    financialYourProjects: "proyectos más importantes.",
    preCreateConnexions: "Creas conexiones ",
    createConnexions: "con potenciales aliados.",
    preNetwork: "Das a conocer ",
    network: "tu empresa en la comunidad.",
    getInspired: {
      title: {
        inspiredFor: "Inspírate para tu",
        nextCampaign: "próxima campaña."
      },
      subtitle:
        "Explora nuestra vitrina para conocer cómo otros empresarios han estructurado sus campañas y hoy están un paso más cerca de poner en marcha sus proyectos."
    }
  },
  successfulCampaignGuide: {
    title: "Guía para una campaña exitosa",
    description:
      "Encuentra una guía con recomendaciones para lanzar una campaña exitosa y transparente para conectar con tus potenciales inversionistas.",
    cardTitle: "Guía para tu campaña",
    download: "Descargar",
    showOnline: "Ver Online"
  },
  filterCampaigns: {
    sector: "Sector",
    classification: "Clasificación del proyecto",
    interestPayment: "Frecuencia de pago interés",
    capitalPayment: "Frecuencia de pago capital",
    term: "Plazo",
    businessLine: "Linea de Negocios",
    capitalManagement: "Manejo de capital",
    interestManagement: "Manejo de intereses",
    roundType: "Tipo de ronda",
    "convertible-note": "Nota convertible",
    debt: "Deuda",
    shares: "Acciones",
    convertibleNote: "Nota convertible"
  },
  quotesList: {
    title: "Lo que dicen nuestros visionarios",
    seeMoreTestimonials: "Ver más testimonios",
    seeLessTestimonials: "Ver menos"
  },
  infoSimulatorForm: {
    amount: "Monto a financiar*",
    term: "Plazo*",
    fixedRate: "Tasa fija*",
    percentage: "% E.A.",
    capitalPayment: "Pago de Capital*",
    interestPayment: "Pago de intereses*",
    capitalGracePeriod: "Período de gracia de capital",
    fngWarranty: "Garantía FNG",
    payPeriodically: "Pagas periódicamente",
    payPeriodicallyGrace: "Pagas periódicamente durante\n el periodo de gracia",
    fees: "Cuotas a pagar",
    select: "Seleccionar",
    default: "Selecciona",
    noApply: "No aplica",
    toExpiration: "Al vencimiento",
    fngCoverage: "Cobertura FNG",
    successCommision: "Comisión de Éxito",
    administratioCommision: "Comisión de Administración",
    tooltipIdealAmount:
      "Este es el monto que necesitas para financiar tu proyecto. Recuerda que debe ser igual o superior a $100M",
    tooltipFngCoverage:
      "Es la comisión pagada al FNG para que\n respalde tu deuda, se paga anual anticipada\n sobre el saldo del capital.",
    tooltipSuccessCommision:
      "Solo se cobra si logras tu meta de financiación. Se descuenta en el\n momento del desembolso y depende directamente del plazo\n elegido y el monto a financiar",
    tooltipAdministrationCommision:
      "Corresponde a un porcentaje del capital que pagas periódicamente",
    dontForgetDownload:
      "No olvides descargar el detalle de tu simulación\n para ver el plan de pagos",
    learnUseSimulator: "Aprende a utilizar el simulador.",
    remember:
      "* Recuerda que ésta es una simulación. Los detalles finales de tu financiación dependen de las condiciones aprobadas durante el proceso.",
    buttonFinnanceYou: "¡Finánciate ahora!"
  },
  paymentError: {
    paragraph1: "Por favor vuelve a intentar más tarde.",
    title1: "No podemos continuar",
    title2: "con tu transacción",
    warningTitle1: "¡Ha ocurrido",
    warningTitle2: "un error!",
    goBack: "Regresar",
    contactUs: "Contactanos",
    errors: {
      warning: "Inténtalo de nuevo para finalizar el proceso",
      "PSE-CREATE_TRANSACTION-001":
        "Se ha excedido el límite del comercio permitido en PSE, para obtener más información puedes escribirnos a a2censo@bvc.com.co.",
      generic:
        "Por favor vuelve a Inténtalo más tarde o escríbenos a a2censo@bvc.com.co para ayudarte."
    }
  },
  faq: {
    frequent: "Preguntas",
    questions: "frecuentes",
    search: "Buscar...",
    a2censo: "a2censo"
  },
  bvcBacking: {
    title: "¿Cómo te respalda",
    answer: "?",
    experience: "Experiencia",
    description:
      " Te respaldan más de 90 años en el mercado de valores colombiano, prestando la infraestructura tecnológica para el crecimiento de la economía del país.",
    services: "Servicios de depósito",
    descriptionServices:
      "Deceval una empresa bvc, se encarga de la administración de los Valores de financiación colaborativa que tu compañía emita a través de a2censo."
  },
  user: {
    documentTypes: {
      1: "C.C",
      2: "CE",
      3: "NIT"
    }
  },
  selfAppraisal: {
    title: "Confírmanos tu identidad",
    subtitle:
      "¡Conocer a tu empresa es lo primero! Sólo necesitaremos algunos datos para continuar.",
    sendButton: "Guardar y Continuar",
    inputQuestion: "Escribe el año de constitución",
    requiredField: "Completa esta pregunta para continuar."
  },
  confirmDataPyme: {
    name: "Razon Social",
    titleSection: "Confírmanos los datos de tu empresa",
    continue: "Guardar y Continuar",
    loading: "Cargando",
    titleForm: "Datos generales",
    address: "Dirección",
    countryCode: "Cod país",
    sectionDescription:
      "Completa la siguiente información para finalizar tu registro.",
    ciiuTitle:
      "¿Cuál es el sector económico principal de tu empresa? (Código CIIU)",
    ciiuOptionalTitle: "¿Tienes Sector Económico secundario? (Opcional)",
    ciiuPlaceholder: "Escribe el código (Ej.O143)",
    cityPlaceholder: "Selecciona la ciudad",
    statePlaceholder: "Selecciona el departamento",
    tooltips: {
      ciiu: {
        title: "Algunos sectores económicos por ahora no podrán",
        subtitle: "\nparticipar. <b>Están restringidos:</b>",
        description: `Movimientos politicos, Juegos de azar, Casas de cambio,
         Cooperativas, Originadores de crédito, Intermediación
         financiera, Comunidades religiosas, Agricultura, Ganadería,
         Caza, Silvicultura y pesca, Explotación de minas y
         canteras, Administración pública y defensa, Planes de
         seguridad social de afiliación obligatoria, Actividades de
         organización extraterritoriales (incluyendo ONG's),
         Construcciones de obras civiles o vivienda.`
      },
      doc: ""
    },
    validations: {
      address: {
        required: "Debes ingresar la dirección",
        max: "Has superado el límite de caracteres"
      },
      state: {
        required: "Debes seleccionar un Departamento"
      },
      city: {
        required: "Debes seleccionar una ciudad"
      },
      number: "Debe ser un valor numérico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten letras.",
        required: "Debes ingresar tu nombre"
      },
      doc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento"
      },
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email"
      },
      phoneCode: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el codigo de pais"
      },
      phone: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten numeros.",
        required: "Debes ingresar tu telefono"
      },
      ciiu: {
        invalid: "Este sector económico no es válido",
        required: "Debes ingresar un CIIU"
      },
      dv: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar un DV"
      }
    },
    DataPymeModal: {
      sure: "Continuar",
      return: "Cancelar",
      confirmationMessage:
        "¿Tus datos son correctos?, ten en cuenta que después no podrás modificarlos"
    },
    DataPymeModalSuccess: {
      sure: "Vincular",
      title: "¡Registro exitoso!",
      confirmationMessage:
        "ya tienes una cuenta en a2censo, desde ya podrás iniciar con la vinculación de tu empresa, para esto necesitarás:",
      option_one: "Información adicional de la empresa.",
      option_two: "Datos del representante legal.",
      option_three: "Información de los socios y/o accionistas"
    },
    PymeVinculationSuccess: {
      title: "¡Vinculación exitosa!",
      message:
        "Desde ya puedes financiar tus proyectos más importantes de la mano de nuestra comunidad.",
      sure: "OK"
    },
    validationsConfirm: {
      title: "¡Lo sentimos!",
      title2: "¡Recibimos tus datos!",
      back: "Regresar",
      codes: {
        20: "Gracias por esperarnos. En un momento cargaremos la información.",
        "055":
          "El CIIU de tu empresa pertenece a los sectores que por ahora no pueden financiarse en a2censo.",
        416: "Recuerda que por ser colaborador bvc no puedes financiarte en a2censo.",
        1002: "Recuerda que por ser accionista bvc no puedes financiarte en a2censo.",
        1003: "Recuerda que por ser accionista bvc no puedes financiarte en a2censo.",
        generic:
          "Estamos teniendo inconvenientes y no podemos continuar con tu registro.",
        "EXPERIAN-001":
          "Con estos validaremos tu información y te comunicaremos vía correo la respuesta.",
        "EXPERIAN-002":
          "Estamos teniendo inconvenientes y no podemos continuar con tu registro.",
        "EXPERIAN-003":
          "Estamos teniendo inconvenientes y no podemos continuar con tu registro.",
        "EXPERIAN-004":
          "Estamos teniendo inconvenientes y no podemos continuar con tu registro.",
        1016: "Gracias por esperarnos. En un momento cargaremos la información.",
        "EXPERIAN-000":
          "Gracias por esperarnos. En un momento cargaremos la información.",
        CODEBTORS_001:
          "Estamos teniendo inconvenientes y no podemos continuar con tu solicitud."
      },
      pendingRiskDescription:
        "Con estos validaremos tu información y te daremos respuesta lo más pronto posible."
    }
  },
  selfAppraisalModal: {
    title: "¡Lo sentimos!",
    description1: "En este momento ",
    back: "OK",
    description2:
      " no cumple con algunos requisitos para vincularse en a2censo. Más adelante podemos ser la mejor opción para acceder a financiación y el apoyo de nuestra comunidad."
  },
  CreateCampaign: {
    save: "Guardar",
    saving: "Guardando",
    saved: "Guardado",
    send: "Enviar",
    knowOwnCampaign: "Conoce nuestra campaña",
    title: "Completa la información de tu campaña",
    nameOfCampaign: "Nombre de la campaña *",
    whatWillWeDo: "¿Qué haremos? *",
    whyTrust: "¿Por qué confiar en nuestro proyecto? * ",
    addButton: "+ Agregar Otro",
    howWillWeMakeItHappen: "¿Cómo lo haremos realidad? *",
    risks: "¿Qué riesgos has identificado y cómo piensas mitigarlos? *",
    tooltips: {
      campaignName:
        "Debe ser un nombre atractivo y no superar los 100 caracteres incluyendo espacios",
      whyTrustAtUs:
        "Indícale a tus inversionistas las razones positivas\n que respaldan tu proyecto.",
      whatWillWeDo:
        "Cuéntale a tus inversionistas de qué se trata tu\n proyecto, tus objetivos, el alcance y los resultados\n que esperas obtener.",
      whatWillWeDoStepSeven:
        "Debes hacer una descripción clara del destino de los recursos, la cantidad que necesitas y cómo serán usados. Ten en cuenta la redacción y la ortografía.",
      howWillWeMakeItHappen:
        "Describe la manera en la que vas a desarrollar tu\n proyecto.",
      risks:
        "En todo proyecto existe un riesgo, cuéntale a tus\n inversionistas cuales has indentificado y cómo los\n mitigaras para aumentar su confianza.",
      impacts:
        "Conecta con tus potenciales inversionistas indicando\n cómo impacta positivamente a la sociedad el\n proyecto que quieres financiar.",
      stepSeven: {
        whyTrustAtUs:
          "Cuenta las principales razones de por qué el inversionista debe confiar en tu proyecto, Ejemplo: Por tu trayectoria, por tu volumen de ventas, por la proyección del nuevo negocio, etc. Ten en cuenta la redacción y la ortografía.",
        whatWillWeDo:
          "Debes hacer una descripción clara del destino de los recursos, la cantidad que necesitas y cómo serán usados. Ten en cuenta la redacción y la ortografía.",
        howWillWeMakeItHappen:
          "Haz un resumen del paso a paso de la ejecución de tu proyecto, enumera los hitos importantes y cuéntaselos a tus posibles inversionistas. Ten en cuenta la redacción y la ortografía.",
        risks:
          "Cuáles son esos riesgos que han identificado para el desarrollo de las actividades de la compañía o la ejecución del proyecto. No está mal tener riesgos, es algo normal, en éste punto en específico es importante contar qué plan de acción tienen para mitigarlos. Ten en cuenta la redacción y la ortografía.",
        impacts:
          "Indicanos los aspectos positivos de tu proyecto, ten en cuenta que por ahora esta información no será visible en el detalle de tu campaña."
      }
    },
    impacts: {
      title: "¿Qué impacto positivo generará tu proyecto? * ",
      social: "Social",
      innovation: "Innovación",
      financial: "Financiero",
      env: "Ambiental",
      other: "Otros",
      otherName: "Nombre del impacto",
      otherDescription: "Descripción"
    },
    financeConditions:
      "¿Cuáles son las condiciones con las que deseas financiar tu proyecto?",
    simulator: {
      title: "Simula tu financiación",
      boldTitle: "para campañas de deuda",
      description:
        "Aquí debes indicar la fuente de financiación del porcentaje restante de tu proyecto. Indícanos por favor el nombre de la(s) entidad(es) y el porcentaje en cada una de ellas.",
      idealAmount: "Monto ideal *",
      idealAmountErr:
        "Por favor revisa el monto mínimo y vuelve a enviar la información",
      idealAmountErrMin:
        "Debes ingresar un monto igual o superior a ${{amount}}",
      minAmount: "Monto mínimo *",
      capitalGracePeriod: "Frecuencia de pago capital *",
      interestGracePeriod: "Frecuencia de pago intereses *",
      fixedRate: "Tasa de interés *",
      term: "Plazo de financiación *",
      capitalGracePeriodicity: "Periodo de gracia de capital *",
      interestGracePeriodicity: "Periodo de gracia de intereses *",
      percentage: "Porcentaje del proyecto que deseas financiar en a2censo *",
      remainingFinancingValue: "Valor restante de financiación del proyecto *",
      minFixedRateErr: "La tasa fija debe ser superior a 0%"
    },
    submitButtons: {
      save: "Guardar",
      continue: "Continuar",
      loading: "Cargando ..."
    },
    validations: {
      max: "Ha superado el número de caracteres permitido",
      maxPercentage: "El porcentaje debe ser menor a 100%",
      campaignNameRequired: "El nombre de la campaña es requerido"
    }
  },
  companyCampaignInfo: {
    backButton: "Mis campañas",
    title: "Crear campaña",
    tabs: {
      tab1: "Información de tu campaña",
      tab2: "Documentos",
      tab3: "Entrevista",
      tab4: "Información complementaria",
      tab5: "Análisis de%br Comité",
      tab6: "Pre-aprobación de tu campaña",
      tab7: "Publica tu campaña"
    }
  },
  requestCampaign: {
    stepOne: {
      alertSuggestion:
        "Por favor revisa el monto ideal y vuelve a enviar la información"
    },
    stepTwo: {
      requestChangeDocuments: {
        title: "Documentos requeridos",
        paragraph1:
          "En línea con regulación colombiana y con el objetivo de conocer mejor a tu empresa, es necesario que cargues todos los documentos.",
        countApprove:
          "Tienes aprobados {{approveFiles}} de los {{totalFiles}} documentos, actualizalos para continuar con el proceso."
      }
    },
    stepFour: {
      alert:
        "El resultado de la entrevista ha sido positivo, por favor completa la siguiente información para seguir con el proceso.",
      documentCEAlert:
        "Ten en cuenta que un codeudor con Cédula de Extranjería solo será aceptado mientras declare renta en Colombia. La Cédula de Extranjería ingresada debe ser expedida por Migración Colombia."
    },
    stepSeven: {
      title: "Publica tu campaña",
      previewButton: "Previsualizar campaña",
      subtitle:
        " A continuación debes completar y/o ajustar la información que verán tus inversionistas una vez tu campaña este publicada",
      infoText: "Para conocer cómo cargar la información de tu campaña ",
      infoTextLink: "haz clic aquí.",
      titleCampaignImage: "Imagen o video para el detalle de tu campaña",
      errorLink: "Por favor ingresa un link válido.",
      subtitleCampaignImage:
        "Puedes agregar el link de tu video o cargar la imagen que represente mejor tu campaña para los potenciales inversionistas.",
      images: {
        placeholder: "Haz clic aquí o arrastra la imagen",
        campaignImage: {
          label: "Imagen de la campaña",
          description:
            "Debe tener relación con tu campaña y se verá en la vitrina.",
          tooltip:
            "Esta debe cumplir con las siguientes condiciones: \n \n - La imagen debe contar con el contenido principal centrado,para que pueda visualizarse correctamente. \n - Tamaño: 500x500 px a 800x800 px \n - Formato: JPG y PNG \n - El peso debe ser menor a 5 mb",
          formatError:
            "Por favor carga un archivo con los formatos y/o tamaño permitido",
          formatError2:
            "Por favor carga un archivo con los formatos y/o tamaño permitido"
        },
        companyLogo: {
          label: "Logo de la empresa",
          description:
            "Con este, los inversionistas podrán identificar tu marca",
          tooltip:
            "Esta debe cumplir con las siguientes condiciones: \n \n - La imagen debe contar con el contenido principal centrado, para que pueda visualizarse correctamente. \n - Tamaño: 400x400 px a 500x500 px \n - Formato: JPG y PNG \n - El peso debe ser menor a 5 mb"
        },
        detailImage: {
          label: "Imagen del detalle de la campaña *",
          description:
            "Esta debe cumplir con las siguientes condiciones: Tamaño de 600x600 px a 800x800 px, formato: JPG o PNG y máximo de 5 mb."
        },
        video: {
          label: "Link del video del detalle de la campaña *",
          description:
            "Acá puedes agregar un link de Youtube o Vimeo para cargar tu video."
        }
      }
    }
  },
  requestChangeDocuments: {
    title: "Documentos requeridos",
    paragraph1:
      "Para poder completar el análisis de tu campaña en comité necesitamos tu ayuda corrigiendo y/o cargando los siguientes documentos.",
    paragraphStepFiveAndFour:
      "Para poder completar el análisis de tu campaña en esta etapa necesitamos tu ayuda corrigiendo y/o cargando los siguientes documentos.",
    paragraph2:
      "Recuerda que podrás cargar tus archivos de hasta 25Mb en los siguientes formatos: ",
    fileFormats: "Excel, Powerpoint, word, PDF, JPG, .ZIP y/o .RAR",
    stepFive: {
      paragraph:
        "Para poder completar el análisis de tu campaña en esta etapa necesitamos tu ayuda corrigiendo y/o cargando los siguientes documentos.",
      paragraph2:
        "Recuerda que podrás cargar tus archivos de hasta 25Mb en los siguientes formatos: ",
      fileFormats: "Excel, Powerpoint, word, PDF, JPG, .ZIP y/o .RAR"
    },
    block: {
      downloadLabel: "Descargar",
      guideDownloadLabel: "Descargar instructivo",
      loading: "Cargando...",
      placeholder: "Has clic aquí o arrasra el documento pra subirlo",
      labelDrag: "Suelta el archivo aquí",
      error: "Por favor carga un archivo con los formatos y/o tamaño permitido"
    }
  },
  RequestCampaignPendingApprove: {
    title: "Análisis de comité en proceso",
    subtitle:
      "Te notificaremos cuando la evaluación del comité bvc esté disponible.",
    stepFive: {
      title: "Tu campaña ha ingresado a comité",
      subtitle:
        "Esta será revisada por nuestro equipo de expertos y te notificaremos el resultado vía correo."
    }
  },
  dashboardCampaings: {
    title: "Mis ",
    titleBold: "campañas",
    titleRequestBold: "solicitudes",
    createCampaign: "Crear campaña",
    campaignDetailShares: "Porqué hacerte socio",
    campaignDetailConvertibleNote: "Porqué invertir",
    meet: "Conoce a ",
    myCompany: "Mi empresa",
    emptyCampaigns: "Crea tu primer campaña y cumple ",
    emptyCampaignsBold: "tus metas de crecimiento",
    pendingDescription: "Para crear tu campaña debes\n",
    pendingDescription2: "completar el perfil de tu empresa.",
    dashboard: {
      description1: "¿Necesitas ayuda? Comunícate con nosotros ",
      description2:
        "Ten en cuenta estos tips para crear tu campaña en a2censo. ",
      clickHere1: "haciendo clic aquí.",
      clickHere2: "Haz clic aquí."
    },
    pending: {
      description1: "¿Necesitas ayuda? Comunícate con nosotros ",
      description2:
        "Ten en cuenta estos tips para crear tu campaña en a2censo. ",
      clickHere1: "haciendo clic aquí.",
      clickHere2: "Haz clic aquí."
    },
    empty: {
      description1: "¿Necesitas ayuda? Comunícate con nosotros ",
      description2:
        "Ten en cuenta estos tips para crear tu campaña en a2censo. ",
      clickHere1: "haciendo clic aquí.",
      clickHere2: "haciendo clic aquí."
    },
    states: {
      creation: "¡Estás creando tu campaña!",
      pending_correction: "¡Debes actualizar tu información!",
      on_hold: "¡Estamos revisando tu información!",
      opened: "Tu campaña está activa",
      cancelled: "Tu campaña ha sido cancelada",
      active: "Tu campaña está activa",
      inactive: "Tu campaña está inactiva",
      closed: "Tu campaña ha cerrado exitosamente",
      awarded: "Tu campaña se ha adjudicado",
      deserted: "Tu campaña no logró la meta",
      award_in_process: "Tu campaña ha cerrado exitosamente",
      annotation_in_proces: "Tu campaña ha cerrado exitosamente",
      annotated: "Tu campaña ha cerrado exitosamente",
      failed_annotation: "Tu campaña ha cerrado exitosamente",
      pending_campaign_request: "¡Estás creando tu campaña!",
      pending_financial_documentation: "¡Estás creando tu campaña!",
      pending_initial_filters: "¡Estamos revisando tu información!",
      pending_additional_documents_first_documents:
        "¡Debes actualizar tu información!",
      reject_without_notifying: "¡Estamos revisando tu información!",
      rejected: "Campaña rechazada, revisa tu correo",
      pending_correction_financial_documentation:
        "¡Debes actualizar tu información!",
      pending_schedule_interview: "¡Estamos revisando tu información!",
      pending_interview_results: "¡Estamos revisando tu información!",
      pending_additional_information: "¡Estás creando tu campaña!",
      pending_codebtor_validation: "¡Estamos revisando tu información!",
      pending_new_codebtor_validation: "¡Debes actualizar tu información!",
      pending_additional_information_validation:
        "¡Estamos revisando tu información!",
      pending_additional_documents_additional_information:
        "¡Debes actualizar tu información!",
      pending_correction_additional_information:
        "¡Debes actualizar tu información!",
      pending_committee_analysis: "¡Estamos revisando tu información!",
      pending_additional_requests_committee:
        "¡Debes actualizar tu información!",
      draftActive: "Tu campaña está activa",
      pending_correction_committee_documentation:
        "¡Debes actualizar tu información!",
      pending_confirmation_financial_conditions: "¡Estás creando tu campaña!",
      pending_final_analysis_information: "¡Estás creando tu campaña!",
      pending_pre_approval: "¡Estamos revisando tu información!",
      pending_correction_pre_approval_documentation:
        "¡Debes actualizar tu información!",
      pending_additional_pre_aproval_documents:
        "¡Debes actualizar tu información!",
      pending_release: "¡Estás creando tu campaña!",
      pending_load_new_codebtor_release: "¡Debes actualizar tu información!",
      pending_commercial_approval: "¡Estamos revisando tu información!",
      release_rejected: "¡Debes actualizar tu información!",
      published_campaign: "Tu campaña está activa",
      pending_lists_validation: "¡Estamos revisando tu información!",
      pending_correction_publish_your_campaign_documentation:
        "¡Debes actualizar tu información!"
    },
    continue: "Continuar",
    seeDetail: "Detalles",
    seeCampaign: "Ver campaña"
  },
  company: {
    save: "Guardar",
    saving: "Guardado",
    validations: {
      max: "Ha superado el número de caracteres permitido",
      colon: "No puedes usar dos puntos sin un espacio a continuación."
    },
    partner: "Socio",
    completeYourInfo: "Completa el perfil de tu empresa",
    completeYoutTeamInfo: "Completa el perfil de tu equipo",
    yourTeamInfo: "Perfil de tu equipo",
    whatDoYourCompanyDo: "¿Qué hace tu empresa?",
    whatDoYourCompanyDoRequired: "¿Qué hace tu empresa? *",
    briefDescriptionOfTheCompany: "Breve descripción de la empresa *",
    whoIsYourCompetition: "¿Quién es tu competencia? *",
    whatAreYourBusinessLines: "¿Cuáles son tus líneas de negocio?",
    info:
      "Recuerda que toda tu información estará segura, y nos permitirá acompañarte en el proceso",
    whoAreYourMainClients: "¿Quiénes son tus principales clientes?",
    whoAreYourCompetition: "¿Quién es tu competencia?",
    whoAreYourMainSuppliers: "¿Quiénes son tus principales proveedores?",
    whatIsTheAddValueFromYourCompany:
      "¿Cuál es el valor agregado que ofrece tu empresa?",
    tellUsHowYouFinanceYourCompany:
      "Cuéntanos cómo y con qué condiciones te financias hoy",
    tellUsYourFuturePlan: "Cuéntanos de tus planes a futuro",
    tellUswhatDoYourCompanyDo: "Cuéntanos que hace tu empresa",
    writewhoAreYourMainClients:
      "Escribe los principales clientes de tu empresa",
    writewhoAreYourMainSuppliers:
      "Escribe los principales proveedores de tu empresa",
    writewhoAreYourCompetition: "Escribe la competencia de tu empresa",
    tellUswhatIsTheAddValueFromYourCompany:
      "Cuéntanos el valor agregado de tu empresa",
    writetellUsHowYouFinanceYourCompany:
      "Escribe cómo y con que condiciones te financias hoy",
    wrtietellUsYourFuturePlan: "Escribe tus planes a futuro",
    tellUswhatAreYourBusinessLines:
      "Cuéntanos cuáles son las lineas de negocio de tu empresa",
    partnerCareer: "Perfil académico",
    partnerProfession: "Perfil profesional",
    writepartnerProfession: "Escribe el perfil profesional",
    writepartnerCareer: "Escribe el perfil académico",
    writeTheName: "Escribe el nombre",
    writeTheRol: "Escribe el cargo de esta persona",
    writeTheDocument: "Escribe el número de la tarjeta profesional",
    infoCompleted: "Has completado el perfil de tu empresa",
    infoReady: "Ya estás listo para crear tu campaña",
    goToMyCampaigns: "Ir a mis campañas",
    validationFormat:
      "Verifica el formato permitido:\n jpg, jpge o png. Maximo 1mb",
    validationDimensions: "Verifica el formato permitido:\n ",
    professionCard: "Número de tarjeta profesional",
    needInfo: "Información incompleta",
    doYouWantThisInformationToBePublished:
      "¿Quieres que esta información sea publicada?",
    tooltips: {
      whatDoYourCompanyDoRequired:
        "Cuéntale al mundo sobre tu compañia, hace cuánto nació, en qué sector se mueven y cuál es su especialidad. Este es el campo para plasmar toda la experiencia y transmitir seguridad sobre la solidez de tu empresa. Ten en cuenta la redacción y la ortografía.",
      briefDescriptionOfTheCompany:
        "Hazle un resumen a tus inversionistas sobre tu empresa, puedes tomar como base la información del campo anterior para llamar su atención desde la vitrina.",
      whoIsYourCompetition:
        "Indícanos cuáles son tus principales competidores, ten en cuenta que por ahora esta información no será visible en el detalle de tu campaña.",
      whatAreYourBusinessLines:
        "Háblanos acerca de las líneas de negocio de tu compañía y haz una breve descripción de cada una, también puedes hablar de la proyección de apertura de nuevas líneas. Ten en cuenta la redacción y la ortografía.",
      whoAreYourMainSuppliers:
        "Ingresa los nombres de tus principales proveedores, esto ayudará a entender mejor el funcionamiento de tu compañía. Ten en cuenta la redacción y la ortografía.",
      whatIsTheAddValueFromYourCompany:
        "Cuéntanos qué hace diferente a tu compañía respecto a sus competidores en el sector. Ten en cuenta la redacción y la ortografía.",
      tellUsYourFuturePlan:
        "Habla de la proyección de crecimiento de tu compañía para los próximos años y cómo impactará el proyecto. Habla en términos de cifras, recuerda que éstas deben estar respaldadas y deben ser claras para el inversionista. Ten en cuenta la redacción y la ortografía.",
      whoAreYourMainClients:
        "Ingresa los nombres de tus principales clientes o los sectores que atiendes, esto ayudará a mostrar mayor solidez en tu compañía. Ten en cuenta la redacción y la ortografía."
    }
  },
  required: "Este campo es requerido",
  document: "Documento",
  uploadProfilePicture: "Subir foto de perfil",
  uploadPicture: "Subir foto",
  rol: "Cargo *",
  addOther: "Agregar otro",
  other: "Otro",
  nameAndLastName: "Nombres y Apellidos *",
  legalName: "Razón Social",
  pictureSupport:
    "Los archivos permitidos son jpg, jpeg y png, de un tamaño máximo de 1 mega.",
  taxAuditor: "Revisor Fiscal (Opcional)",
  cto: "Director técnico",
  cfo: "Rol #3",
  commercialDirector: "Rol #2",
  ceo: "Gerente general",
  remove: "Eliminar",
  createCampaignFormTooltips: {
    percentage:
      "Déjanos saber si tu proyecto será financiado 100% en a2censo, de lo contrario debes indicar qué porcentaje será financiado con otras entidades. Indícanos cuáles.",
    minAmount:
      "Se refiere al monto mínimo con el que puedes ejecutar tu proyecto, este debe ser igual o superior al 70% del monto ideal",
    capitalGracePeriod:
      "Evalúa si necesitas un periodo de gracia para capital, este periodo se refiere al tiempo que tu empresa no realizará pagos de capital. Una vez culminado dicho periodo deberás pagarlo según corresponda",
    interestGracePeriodicity:
      "Evalúa si necesitas un periodo de gracia de intereses, este se refiere al tiempo que tu empresa estará libre del pago de intereses a tus potenciales inversionistas, una vez culminado dicho periodo estos intereses deberás pagarlos según corresponda.",
    idealAmount:
      "Este es el monto que necesitas para financiar tu proyecto. Recuerda que debe ser igual o superior a $100M",
    fixedRate:
      "Es la rentabilidad que ofrecerás a tus inversionistas en % E.A. Esta se refiere a la tasa real con la que puedes calcular un interés o rendimiento en un año corrido"
  },
  interview: {
    locationLabel: "Ubicación",
    titleLabel: "Tu entrevista es:",
    dateLabel: "Fecha",
    hourLabel: "Hora",
    emailLabel: "¿Dudas? Escribenos a a2censo.comercial@bvc.com.co",
    pendingLabel: "Te avisaremos cuando tu entrevista haya sido programada.",
    buttonLabel: "Ir a la videollamada",
    subtitleLabel: "¡Estás cada vez más cerca! ",
    descriptionLabel:
      "Es el momento de conocernos. Recuerda que el resultado de tu entrevista se enviará por correo."
  },
  RequestCampaignStepSeven: {
    pedingApprove: {
      title: "¡Tu campaña está lista!",
      description1: "Nuestro equipo te ayudará a realizar una",
      description2: "última revisión."
    }
  },
  RequestCampaignStepTwo: {
    save: "Guardar",
    saving: "Guardando",
    saved: "Guardado",
    send: "Enviar",
    title: "Documentos requeridos",
    description:
      "En línea con regulación colombiana y con el objetivo de conocer mejor a tu empresa, es necesario que cargues todos los documentos.",
    description2:
      "Recuerda que podrás cargar tus archivos de hasta 25Mb en los siguientes formatos: ",
    formats: "Excel, PowerPoint, Word, PDF, JPG, .ZIP y/o .RAR",
    blockFile: {
      labelDownload: "Descargar",
      downloadGuide: "Descargar instructivo",
      fileUploader: {
        placeHolder: "Haz clic aquí o arrastra el documento para subirlo",
        error:
          "Por favor carga un archivo con los formatos y/o tamaño permitido",
        loadError: "Ha ocurrido un error, por favor intenta de nuevo",
        loading: "Cargando archivo:",
        labelDrag: "Suelta el archivo aquí"
      }
    },
    pending: {
      message1:
        "Recibimos tus documentos para iniciar con el proceso de evaluación.",
      message1a: "¡Prepárate para tu entrevista!",
      message2:
        "Recuerda que te comunicaremos vía correo el resultado de esta etapa."
    }
  },
  RequestCampaignStepFour: {
    codes: {
      CODEBTORS_004: {
        title: "¡Lo sentimos!",
        description:
          "Tu codeudor socio es colaborador bvc, por lo tanto no puedes continuar con la solicitud."
      },
      CODEBTORS_003: {
        title: "¡Lo sentimos!",
        description:
          "Tu codeudor socio es accionista bvc, por lo tanto no puedes continuar con la solicitud."
      },
      CODEBTORS_001: {
        title: "¡Lo sentimos!",
        description:
          "Estamos teniendo inconvenientes y no podemos continuar con tu solicitud."
      },
      CODEBTORS_002: {
        title: "¡Recibimos tu información!",
        description:
          "Con esto validaremos a tus codeudores y te comunicaremos vía correo la respuesta."
      },
      CODEBTORS_008: {
        title: "¡Lo sentimos!",
        description:
          "Tu codeudor externo es colaborador bvc, ingresa uno nuevo para continuar con la solicitud."
      },
      CODEBTORS_007: {
        title: "¡Lo sentimos!",
        description:
          "Tu codeudor externo es accionista bvc, ingresa uno nuevo para continuar con la solicitud."
      },
      CODEBTORS_005: {
        title: "¡Lo sentimos!",
        description:
          "Para poder continuar con el proceso debes revisar los codeudores seleccionados."
      },
      CODEBTORS_006: {
        title: "¡Recibimos tu información!",
        description:
          "Con esto validaremos a tus codeudores y te comunicaremos vía correo la respuesta."
      }
    },
    save: "Guardar",
    saving: "Guardando",
    saved: "Guardado",
    send: "Enviar",
    title: "Documentos de tus socios",
    description:
      "Carga los siguientes documentos para conocer mejor a los socios de tu empresa, acá podrás subir también los solicitados previamente en la entrevista.",
    description2:
      "Recuerda que podrás cargar tus archivos de hasta 25Mb en los siguientes formatos: ",
    formats: "Excel, PowerPoint, Word, PDF, JPG, .ZIP y/o .RAR",
    blockFile: {
      labelDownload: "Descargar",
      downloadGuide: "Descargar instructivo",
      fileUploader: {
        placeHolder: "Haz clic aquí o arrastra el documento para subirlo",
        error:
          "Por favor carga un archivo con los formatos y/o tamaño permitido",
        loading: "Cargando archivo:",
        labelDrag: "Suelta el archivo aquí"
      }
    },
    confirmInformation: {
      title: "Reconfirma tu información de campaña",
      titleOk: "Has confirmado tu información de campaña",
      textOk:
        "Has completado los cambios de tu campaña. Te avisaremos vía correo en caso de una solicitud adicional",
      buttonText: "Consultar"
    },
    completedView: {
      title: "Análisis Integral en proceso",
      description:
        "Hemos recibido la información para analizar tu campaña. Debes estar pendiente de tu correo para conocer el resultado."
    },
    codebtorSelector: {
      yourAssociates: "Tus socios",
      loading: "Guardando...",
      placeholderTitle: "¡Recuerda que tus socios pueden ser codeudores!",
      placeholderSubtitle: "Puedes elegir uno o varios de esta lista"
    },
    yourCodebtors: "Tu(s) codeudor(es) *",
    yourCodebtorsDesc:
      "Puedes seleccionar a uno de tus socios o ingresar uno nuevo, recuerda que este puede ser persona natural o jurídica y respaldará tu financiación."
  },
  RequestCampaignStepFive: {
    title: "Documentos requeridos",
    description:
      "Para poder completar el análisis de tu campaña en esta etapa necesitamos tu ayuda corrigiendo y/o cargando los siguientes documentos.",
    description2:
      "Recuerda que podrás cargar tus archivos en los siguientes formatos: ",
    formats: "Excel, Powerpoint, word, PDF, JPG, .ZIP y/o .RAR",
    errorTermsTitle: "¡Lo sentimos! Debes revisar nuevamente sus condiciones:",
    errorAmount: "El monto no puede ser superior al aprobado por el comité",
    errorFinancialTerms:
      "El plazo de financiación no debe ser inferior al aprobado por comité",
    errorOthers:
      "Los campos de frecuencia y periodos de gracia deben ser iguales a los aprobados por comité"
  },
  RequestCampaignStepSix: {
    header: {
      alert: "Tu campaña ha sido pre-aprobada",
      title: "Formalización de campaña",
      description:
        "Esta información concretará tu campaña. Necesitas a tu representante legal."
    },
    otp: {
      title: "Confirmación representante legal",
      description:
        "El representante legal recibirá un código a su correo registrado en a2censo. Ingrésalo para continuar.",
      codeInputTitle: "Ingresa aquí el código",
      codeError: "Código no válido",
      serviceError: "Intenta de nuevo más tarde"
    },
    termsLabel: "Al seleccionar, aceptas nuestros ",
    termsLink: " términos y condiciones.",
    termsAndConditions: "1. Términos y condiciones",
    requiredDocuments: "2. Documentos requeridos",
    inputBank: "Banco *",
    inputAccountType: "Tipo de cuenta *",
    inputAccountNumber: "Número de cuenta *",
    bankData: "3. Datos bancarios de la empresa",
    bankDataDescription:
      "Por favor ingresa la información de la cuenta bancaria a la cual se destinarán los recursos recaudados de a2censo.",
    companyTax: "4. Información tributaria de la empresa",
    companyTaxDescription:
      "Cuando tu campaña cierre exitosamente, necesitaremos esta información para generar los certificados de retención en la \nfuente a tus inversionistas. Recuerda que esta retención se aplica sobre los pagos de intereses.",
    companyTaxDropOne: "Ciudad de retención",
    companyTaxDropTwo: "Ciudad de consignación",
    companyTaxCityRetention:
      "Selecciona la ciudad donde la empresa aplicará las retenciones sobre los rendimientos pagados.",
    companyTaxCityConsignment:
      "Selecciona la ciudad donde se realizará el pago de las retenciones sobre los rendimientos pagados.",
    checkCompanyTax:
      "La ciudad de consignación es la misma que la ciudad de retención.",
    pendingApprove: {
      title: "¡Prepárate para publicar tu campaña!",
      description:
        "Te confirmaremos por correo una vez revisemos la última información que enviaste."
    },
    blockFile: {
      downloadContract: "Descargar contrato",
      labelDownload: "Descargar"
    }
  },
  familyMember: {
    title: "Familiar #",
    secondLastName: "Segundo Apellido",
    lastname: "Primer Apellido *",
    names: "Nombres *",
    position: "Cargo o Actividad que desempeña actualmente*",
    entiy: "Entidad o empresa actual *",
    required: "(Los campos con * son obligatorios)"
  },
  pendingApproveStage: {
    title: "¡Recibimos tu información!",
    description:
      "Con esto validaremos a tu(s) codeudor(es) y te comunicaremos vía correo la respuesta.",
    link: {
      urlText: "Descubre aquí",
      text: " cómo construir la campaña para tus inversionistas"
    }
  },
  financialTerms: {
    alertChangeRequestEdit:
      "Has editado las condiciones financieras, te notificaremos la evaluación del comité",
    alert: "Condiciones financieras aprobadas por el comité",
    headTitle: "Revisa tus condiciones financieras",
    headSubtitle:
      "Actualiza tus condiciones con base a las aprobadas por el comité.",
    infoMessage1: "Condiciones financieras iniciales.",
    infoMessage2: "Condiciones financieras aprobadas.",
    edit: "Editar",
    idealAmount: "Monto ideal",
    minimalAmount: "Monto mínimo",
    graceCapitalPeriod: "Periodo de gracia capital",
    graceInterestPeriod: "Periodo de gracia interés",
    financingTerm: "Plazo de financiación",
    payCapitalPeriod: "Frecuencia de pago capital",
    payInterestPeriod: "Frecuencia de pago interés",
    interestRate: "Tasa de interés",
    approve: "Aprobar",
    fng: "Fondo Nacional de Garantías (FNG)",
    guaranteLine: "Línea de garantía",
    commissionValue: "Valor de la comisión",
    commissionValueTooltip:
      "Es la comisión pagada al FNG para que respalde tu deuda, se paga anual anticipado sobre el saldo del capital.",
    percentageCoverage: "Porcentaje de cobertura",
    projectClassification: "Clasificación del proyecto",
    companyClassification: "Clasificación de la empresa",
    info: "Condiciones financieras",
    addInfo: "Información adicional",
    comments:
      "Conoce aquí las recomendaciones del comité sobre tus condiciones",
    editedFinancialTerms:
      "Has actualizado las condiciones financieras, te notificaremos la evaluación del comité",
    editedFinancialTermsAndIsApproved:
      "Has actualizado exitosamente tus condiciones, da clic en aprobar para continuar",
    thanksApprove:
      "¡Gracias por aprobar tus condiciones! haz clic en enviar y listo."
  },
  alert: {
    tryAgain: "Ha ocurrido un error. Por favor intenta de nuevo.",
    tryItLater: "Ha ocurrido un error inesperado inténtalo más tarde."
  },
  codebtorList: {
    hasAnotherCodebtor: "¿Tienes otro codeudor?",
    addAnotherCodebtor: "Agregar un nuevo codeudor",
    edit: "Editar",
    form: {
      saving: "Guardando",
      documentTypes: {
        1: "Cédula de Ciudadanía",
        2: "Cédula de Extranjería",
        3: "NIT"
      },
      select: "Seleccionar",
      delete: "Eliminar",
      add: "Agregar",
      addCodebtorTitle: "Agregar un nuevo codeudor",
      documentNumber: "Número del documento *",
      documentType: "Tipo de documento *",
      name: "Primer nombre *",
      nameLegal: "Razón Social *",
      lastName: "Primer apellido *",
      secondLastname: "Segundo apellido",
      secondName: "Otros nombres",
      expeditionDate: "Fecha de expedición *",
      expeditionPlace: "Lugar de expedición *",
      nationality: "Nacionalidad *",
      expeditionCountry: "País de expedición *",
      cityExpeditionPlace: "Ciudad de expedición *",
      deptoExpeditionPlace: "Departamento de expedición *",
      dv: "DV",
      validations: {
        max: "Ha superado el número de caracteres permitido",
        number: "Deben ser numeros",
        name: {
          type: "Solo se permiten letras mayúsculas y sin tilde.",
          required: "El nombre es requerido",
          required_nit: "La razón social es requerida"
        },
        lastName: {
          type: "Solo se permiten letras mayúsculas y sin tilde.",
          required: "El apellido es requerido"
        },
        secondLastname: {
          type: "Solo se permiten letras mayúsculas y sin tilde."
        },
        documentType: {
          required: "El tipo de documento es requerido"
        },
        documentNumber: {
          required: "El documento es requerido"
        },
        documentNumberCe: {
          required:
            "Ingresa únicamente números, sin caracteres especiales ni espacios."
        },
        dv: { required: "El DV es requerido" },
        expeditionDate: {
          required: "Debes ingresar tu fecha de expedición"
        },
        expeditionCountry: {
          required: "La nacionalidad es requerida",
          required_nit: "El país de expedición es requerido"
        },
        expeditionState: {
          required_nit: "El departamento de expedición es requerido"
        },
        expeditionPlace: {
          required: "El lugar de expedición es requerido",
          required_nit: "La ciudad de expedición es requerida"
        }
      }
    }
  },
  inviteToDekstopCampaign:
    "Te invitamos a crear tu campaña desde un computador,",
  inviteToDekstopStrong: "para que tengas la mejor experiencia en a2censo",
  inviteToDekstopCompany:
    "Te invitamos a llenar la información de tu empresa desde un computador,",
  codebtors: "Codeudores",
  RequestCampaignTermsModal: {
    title: "Términos y condiciones",
    accept: "Aceptar"
  },
  campaignApproved: {
    congratulations: "¡Felicitaciones!",
    infoText:
      "Tu campaña ha sido publicada. Gracias por hacer parte de esta comunidad que cree y apoya el talento colombiano.",
    showCaseButton: "Vitrina",
    dashboardCampaignsButton: "Mis Campañas"
  },
  publishCampaign: {
    networks: "Redes Sociales"
  },
  update: "Actualizar",
  jump: "Saltar",
  getIt: "Entendido",
  UpdateInfoSuccessModal: {
    title: "Hemos actualizado exitosamente tu información",
    subtitle: "¡Gracias por confiar en ",
    subtitleBold: "a2censo!",
    buttonText: "Entendido"
  },
  updatePNData: {
    error: "Tus datos no coinciden, revísalos.",
    allowed: "Código CIIU no permitido.",
    updateData: "Actualiza tus datos",
    updateDescription: "Por favor completa el siguiente formulario para tener",
    updateDescription2: "todos tus datos actualizados.",
    addInfo:
      "Ingresa tus nombres y apellidos completos como aparecen en tu documento de identificación",
    form: {
      firstName: "Primer nombre *",
      otherName: "Otros nombres",
      lastName: "Primer apellido *",
      secondLastName: "Segundo apellido",
      validations: {
        firstName: "Campo requerido",
        lastName: "Campo requerido",
        ciiu: "Campo requerido"
      }
    },
    putInfo: "Ingresa el",
    ciiu: "CIIU",
    putInfoAdd:
      "que aparece en tu RUT, te ayudamos con algunos de los más comunes:",
    ciius: {
      "0010": "Asalariados",
      "0081": "Sin Actividad económica, solo para personas naturales",
      "0082": "Personas Naturales, Subsidiadas por terceros",
      "0090": "Rentistas de Capital, solo para personas naturales",
      left1: "0010",
      left2: "0081",
      right1: "0082",
      right2: "0090"
    },
    doubts: "Si tienes dudas sobre tu actividad económica",
    knowHere: "consúltalo aquí.",
    failDescription: "¡Lo sentimos! Estamos teniendo inconvenientes,",
    failDescription2: "por favor intenta en otro momento",
    failAttemps: "Has gastado tus intentos del día.",
    failAttemps2: "Inténtalo mañana."
  },
  updating: "Actualizando",
  myCertificates: {
    pagination: {
      countCertificates: "Certificados: {{current}} de {{total}}",
      loadMore: "Cargar más"
    },
    downloadStart: "Ha comenzado la descarga",
    modalLoading: {
      descriptionDownload: "Estamos preparando tus archivos.",
      descriptionEmail: "Estamos preparando el correo.",
      descriptionbold: "Por favor no cierres esta ventana."
    },
    modalPassword: {
      title: "Ha comenzado la descarga",
      desc1: "Ten en cuenta que para visualizar el documento debes ",
      desc2:
        "digitar tu número de identificación o NIT con código de verificación",
      desc3: " y listo.",
      button: "Aceptar"
    },
    title: "Certificados",
    pyme: {
      title: "Certificados y reportes",
      description: {
        part1:
          "Descárgalos y/o recíbelos en las próximas horas en tu correo registrado en a2censo.",
        part2: ""
      },
      sourceRetentionCertificate: "Certificados de retención en la fuente",
      retentionMovements: "Reporte anual de inversionistas",
      edit: {
        part1: "Puedes editar tus datos de",
        part2:
          " Ciudad de Retención, Ciudad de Consignación, Dirección Comercial y Ciudad ",
        part3: "aquí.",
        button: "Editar",
        commercialAddress: "Dirección Comercial",
        commercialAddressDescription:
          "Indicanos la dirección comercial, donde se encuentra ubicada tu empresa.",
        selectCity:
          "Selecciona la ciudad donde se encuentra ubicada tu empresa.",
        updateData: "Actualización de datos",
        updateDataDescription:
          "Cuando generemos los certificados a tus inversionistas, necesitaremos esta información actualizada, recuerda que estos certificados se generan anualmente."
      }
    },
    investor: {
      title: "Certificados",
      description: {
        part1: "Acá podrás encontrar tus certificados cuando los necesites,",
        part2: " recuerda que estarán disponibles el 15 de marzo de cada año."
      },
      sourceRetentionCertificate: "Retención en la fuente",
      retentionMovements:
        "Retención gravamen a los movimientos financieros y de saldos",
      summaryRetentionReport: "Reporte Resumen de retención en la fuente",
      tooltipSummaryRep:
        "En esta sección puedes enviar a tu correo o descargar el reporte anual de retención en la fuente"
    },
    card: {
      availablePlural: "Disponibles",
      availableSingular: "Disponible"
    },
    successSendEmail: "Hemos enviado el certificado al correo: ",
    successSendMassiveMail: "Hemos enviado los certificados al correo: ",
    help: {
      part1: "¿Necesitas ayuda? Comunícate con nosotros ",
      boldPart: "haciendo clic aquí ",
      part2: "o llámanos al (601) 313 9000",
      modal: {
        heading: "¿Necesitas ayuda?",
        paragraphPart1: "Déjanos tu mensaje y nuestro",
        paragraphPart2: "equipo te contactará.",
        paragraphTwo: "Mensaje",
        buttonText: "Enviar"
      }
    },
    detail: {
      empty: {
        descriptionPar1: "Para el año ",
        descriptionPar2: " no tienes certificados disponibles."
      },
      backButton: "Certificados",
      report: {
        title: "Reporte anual de inversionistas",
        description: {
          part1: "Recuerda que estará disponible ",
          bold: "el primero de enero de cada año."
        }
      },
      retantion: {
        title: "Certificados de retención en la fuente",
        pyme: {
          description: {
            part1: "Recuerda que estarán disponibles por campaña o agrupados ",
            bold: "el 15 de marzo de cada año."
          }
        },
        investor: {
          description: {
            part1:
              "Descárgalos y/o recíbelos en tu correo en las próximas horas, por campaña o agrupados.",
            bold: ""
          }
        }
      },
      financialtax: {
        title: "Certificados de gravamen y saldos",
        description: {
          part1:
            "Selecciona el año para descargar el certificado y/o recibirlo en tu correo en las próximas horas.",
          bold: ""
        },
        card: "Retención gravamen a los movimientos financieros y de saldos"
      }
    },
    emptyCertificates: {
      backButton: "Certificados",
      titleFinancialMovements: "Certificados de gravamen y saldos",
      titleRetentionCertificates: "Certificados de retención en la fuente",
      titleReportAnnual: "Reporte anual de inversionistas",
      description: {
        part1: "Por ahora no tienes ",
        bold: "ningún",
        bold2: "certificado disponible."
      }
    },
    certificateCard: {
      investor: {
        aCampaign: "Campaña",
        someCampaigns: "Campañas",
        labelCompanyName: "Empresa",
        labelDescription: "Campaña más reciente",
        labelQuantity: "Número de campañas"
      },
      pyme: {
        aCampaign: "Inversionista",
        someCampaigns: "Inversionistas",
        labelCompanyName: "Campaña",
        labelDescription: "Campaña más reciente",
        labelQuantity: "Número de inversionistas"
      }
    }
  },
  sendMassiveEmails: "Enviar todos por correo",
  successSave: "Se han guardado los cambios con éxito",
  saving: "Guardando",
  customerService: "Servicio al cliente",
  downloadCertificates: {
    sorry: "¡Lo sentimos!",
    subtitle: "Estamos generando tu certificado",
    message: "Tu descarga comenzará en breve",
    errors: {
      1004: "Para descargar este documento debes <br /> iniciar sesión con <strong> el usuario con el que se <br /> realizo la solicitud </strong>",
      435: "Este link de descarga se ha vencido. <strong>Por <br />favor vuelve a solicitar el certificado en la<br /> sección de Mis certificados.<strong>",
      1029: "Has alcanzado el máximo de descargas por minuto, inténtalo más tarde",
      "062":
        "Para descargar este documento debes <br /> iniciar sesión con <strong> el usuario con el que se <br /> realizo la solicitud </strong>",
      "061":
        "Para descargar este documento debes <br /> iniciar sesión con <strong> el usuario con el que se <br /> realizo la solicitud </strong>"
    },
    homeButton: "Ir a inicio",
    logoutButton: "Cerrar sesión",
    myCertificates: "Mis certificados"
  },
  Oops: {
    title: "Oops!",
    message: "Ocurrio un error al cargar las campañas.",
    boldMessage: "Por favor vuelve a intentarlo.",
    button: "Volver a cargar"
  },
  attemptsValidationModal: {
    text:
      "Has gastado tus intentos del día para validar tu identidad. Inténtalo mañana.",
    button: "Continuar"
  },
  internetAlert: {
    offLineWithSession:
      "No tienes conexión a internet. Por favor revísala y vuelve a iniciar sesión.",
    offLineWithoutSession:
      "No tienes conexión a internet. Por favor revísala. ¡Te esperamos de regreso!"
  },
  searchByName: "Buscar por nombre",
  foreignCETooltip:
    "Ten en cuenta que un codeudor con Cédula de Extranjería solo será aceptado mientras declare renta en Colombia.",
  campaingStates: {
    8: "Cerrada",
    9: "Cerrada",
    10: "Cerrada",
    1: "Abierta",
    2: "Adjudicada",
    3: "Desierta",
    4: "Cerrada",
    5: "Cerrada",
    6: "Cerrada",
    7: "Cerrada"
  },
  businessLines: {
    debt: "Deuda",
    shares: "Acciones",
    convertibleNote: "Nota convertible",
    equity: "",
    descriptions: {
      debt:
        "Es un préstamo que le haces a la empresa y del cual recibes una rentabilidad periódica.",
      shares:
        "Inviertes comprando acciones de una empresa para volverte su socio",
      convertibleNote:
        "Instrumento financiero que te da el derecho a recibir acciones de una empresa si se cumplen las condiciones de conversión de la nota."
    }
  },
  equity: {
    whatIs: "¿Qué son Acciones, Notas Convertibles y",
    howBeEquity: " cómo ser parte?",
    whatIsDescription:
      "Son una opción de inversión a largo plazo en la que podrías convertirte en socio de la empresa y diversificar tu portafolio.",
    whatIsKnowVideo: "Conoce más dando clic al video",
    modal: {
      title: "¿Estás listo para llevar tus inversiones al siguiente nivel?",
      text:
        "Aprende cómo funcionan las inversiones en Acciones y Notas convertibles en a2censo y prepárate para hacer crecer tus ahorros impulsando empresas colombianas",
      understoodButton: "Entendido",
      knowUsButton: "Conocer más"
    },
    know: "Ten en cuenta al invertir en ",
    knowEquity: "Acciones y Notas Convertibles",
    knowDescription:
      "Cada una de estas opciones de inversión tiene características y reglas especiales.",
    knowClic: "Da clic en el video para saber más",
    risk: "Contempla los riesgos",
    riskDescription:
      "Recuerda que toda inversión implica un riesgo, mitígalo invirtiendo en diferentes campañas entre Deuda, Acciones y Notas Convertibles.",
    riskDescriptionStrong:
      " No olvides tomar decisiones basadas en la información.",
    howToInvest: {
      title: "Cómo invertir en ",
      inEquity: "Acciones y Notas Convertibles",
      paso_1_title: "Debes tener una cuenta activa:",
      paso_1_description:
        "Si ya tienes una cuenta la puedes usar, si no, completa todos los datos del formulario y activa tu cuenta para invertir en las diferentes alternativas que tenemos para ti.",
      paso_1_image: "/home/a2censo/how-to-invert/registro.png",

      paso_2_title: "Elige la campaña:",
      paso_2_description:
        "Compara las opciones disponibles entre acciones y notas convertibles, lee la información completa y toma la decisión de acuerdo a tu perfil y necesidades.",
      paso_2_image:
        "/home/a2censo/how-to-invert/ronda.png",

      paso_3_title: "Haz tu inversión:",
      paso_3_description:
        'Realiza tu inversión de manera ágil con los recursos disponibles en "Mi balance" o por PSE.',
      paso_3_image:
        "/home/a2censo/how-to-invert/paso_pse.png"
    },
    makeYourMoneyGrow: {
      title: "Haz crecer tu dinero invirtiendo.",
      description: "Conoce las campañas activas de Acciones y Notas Convertibles.",
      button_text: "Ver campañas"
    }
  },
  sectionTabs: {
    tab1: {
      title: "Vitrina",
      tooltip:
        "Invierte en las campañas disponibles en Deuda, Acciones y Notas Convertibles para impulsar el crecimiento de nuestras empresas colombianas"
    },
    tab2: {
      title: "Deuda",
      tooltip:
        "Encuentra aquí información útil sobre nuestro modelo de deuda para que puedas invertir en las campañas disponibles en la vitrina"
    },
    tab3: {
      title: "Acciones y Notas Convertibles",
      tooltip:
        "¿Tienes dudas sobre acciones y/o notas convertibles? encuentra aquí lo que necesitas saber para invertir en las campañas de nuestra vitrina"
    },
    tab4: {
      title: "¿Por qué elegirnos?",
      tooltip: "Conoce aquí lo que nos hace únicos"
    }
  },
  floatingButtons: {
    documents: {
      nameFloatingButton: "Documentos para descargar",
      titleModal: "Documentos de la campaña",
      publicationDate: "Fecha de publicación",
      downloadButton: "Descargar todos"
    },
    simulator: {
      nameFloatingButton: "Simular inversión"
    }
  },
  informationalSlider: {
    debt: {
      what: {
        title: "Qué es una campaña de Deuda",
        description:
          "Es un préstamo que le haces a una empresa para desarrollar sus proyectos, en a2censo las condiciones de campaña son propuestas por el empresario y de estas dependerán los pagos que recibirás."
      },
      how: {
        title: "Cómo ganas",
        description:
          "Al invertir en estas campañas recibirás unos rendimientos periódicos, recuerda que estos se calculan sobre el saldo a capital y serán pagados según las condiciones establecidas por el empresario."
      },
      risk: {
        title: "Riesgo",
        description:
          "La mayoría de inversiones en Deuda cuentan con el respaldo del FNG, esto hace que el riesgo se reduzca, ya que cubrirá un porcentaje del capital por pagar al momento del incumplimiento del empresario. Las garantías se asignan de acuerdo a las condiciones de cada compañía."
      }
    },
    share: {
      what: {
        title: "Qué son acciones",
        description:
          "Es un activo financiero que divide el valor de una empresa en partes iguales, al comprarlas en a2censo te haces socio y adquieres derechos sobre la empresa según el tipo de acción. %br No olvides consultar las condiciones de las acciones antes de invertir."
      },
      how: {
        title: "Cómo ganas",
        description:
          "Al invertir en acciones tienes la oportunidad de rentabilizar tu dinero a través de la valorización de la empresa."
      },
      risk: {
        title: "Riesgo",
        description:
          "Las inversiones en acciones son de largo plazo y de alto riesgo; una forma de mitigarlo es la diversificación. Asegúrate que estas se ajusten a tu perfil de riesgo antes de invertir. %br Recuerda que las campañas de acciones no cuentan con la garantía del Fondo Nacional de Garantías y bvc no es responsable por los resultados de la empresa ni de tu inversión."
      }
    },
    convertibleNote: {
      what: {
        title: "Qué es nota convertible",

        description:
          "Es un valor de financiación colaborativa que te da el derecho a recibir acciones en el futuro de una empresa, una vez se materialice alguno de los eventos de conversión predefinidos o por desvinculación del emisor de a2censo en los términos y sujeto a las condiciones de la emisión. Durante la vida de la nota esta no genera rendimientos ni tampoco la devolución del capital invertido.%br",
        description2:
          "No olvides consultar las condiciones de la nota y sus eventos de conversión antes de invertir."
      },
      how: {
        title: "Cuándo se convierte",
        description:
          "Se realizará la conversión de la nota en los siguientes escenarios predefinidos:",
        blockI: {
          title: "Evento de liquidez:",
          description: "Un evento de liquidez es:",
          numberedListI:
            "Cuando una persona o grupo de personas adquiera directa o indirectamente más del 50% de las acciones de una empresa y a su vez, representan la cantidad de votos mínima para la toma de decisiones en la asamblea general de accionistas o para elegir la mayoría de miembros de la junta directiva, si la hubiese.",
          numberedListII:
            "Venta, arrendamiento u otra disposición de los activos de la empresa."
        },
        blockII: {
          title: "Evento de capitalización:",
          paragraph:
            "Emisión de acciones por un monto igual o superior al estipulado por la empresa en el lanzamiento de su campaña, excluyendo cualquier venta o emisión de acciones a favor de empleados, directivos, asesores o contratistas."
        },
        blockIII: {
          title: "Evento de liquidación:",
          paragraph:
            "La empresa entra en proceso de liquidación y cierre de operaciones.",
          description:
            "En este evento, será el inversionista quien decida si convierte o no la nota."
        },
        moreInformation:
          "Para mayor información consulta el <b>Anexo Acuerdo de financiación emisión de Notas convertibles</b> de cada campaña en la sección <b>Documentos para descargar</b> y nuestra <b>Circular</b> en <b>Navega > Legal.</b>"
      },
      risk: {
        title: "Riesgo",
        description:
          "Las inversiones en notas convertibles son de largo plazo y de alto riesgo; una forma de mitigarlo es la diversificación. Asegúrate que estas se ajusten a tu perfil de riesgo antes de invertir. %br Recuerda que las campañas de notas convertibles no cuentan con la garantía del Fondo Nacional de Garantías y bvc no es responsable por los resultados de la empresa ni de tu inversión."
      }
    },
    moreInformation: "Ver más información"
  },
  SharesInvestmentModal: {
    title: "Hola,",
    copy:
      "En este momento no están disponibles los paquetes de acciones seleccionados.",
    copyComplement: "Te invitamos a escoger otra cantidad ",
    copyComplement2: "o elegir otra inversión.",
    back: "Regresar",
    showcase: "Ir a vitrina",
    title2: "Ten en cuenta",
    copy2:
      "Esta es una inversión de <b>alto riesgo</b>, de largo plazo y con riesgo de liquidez, recuerda que el riesgo se mitiga a través de la diversificación.<br><br>Para tomar una decisión de inversión informada, debes leer toda la información publicada de la empresa. <b>¡La decisión de invertir siempre será tuya!</b><br><br>bvc no es responsable de los resultados generados por la empresa en la que vas a invertir ni de tu inversión.",
    warning: "¿Deseas continuar?",
    accept: "Aceptar",
    cancel: "Cancelar"
  },
  errorConvertibleNote: {
    YourInvestmentMustBeLess: "Tu inversión debe ser menor a la meta.",
    YourInvestmentMustBeMltiples: "El valor ingresado debe ser en múltiplos de",
    YourInvestmentMustBeequalOrGreater: "Tu inversión debe ser igual o mayor a"
  },
  investDetail: {
    title: "Tu inversión",
    currency: "COP ",
    cop: "COP ",
    subTitle1: "Valor pagado en capital",
    subTitle2: "Capital pendiente por pagar",
    subTitle3: "Rendimientos pagados",
    subTitle1_share: "Paquetes de acciones adquiridos",
    subTitle2_share: "Total de acciones adquiridas",
    subTitle1_note: "Pendiente por convertir",
    titleAmount: "Monto recaudado",
    stateOpen: "Abierta",
    stateAwarded: "Adjudicada",
    stateClose: "Cerrada",
    porcentText: "recaudado",
    goalText: "Meta COP",
    investButton: "Aumentar",
    cancelButton: "Cancelar inversión",
    tooltipDebOne:
      "Corresponde al monto acumulado que se ha venido pagando en capital.",
    tooltipDebTwo:
      "Corresponde al saldo en capital que está pendiente por pagar.",
    tooltipDebThree:
      "Este es el valor recibido en rendimientos acumulados, las retenciones ya han sido descontadas en caso de que apliquen.",
    tooltipNote:
      "Corresponde al valor en capital invertido susceptible a ser convertido en acciones."
  },
  searchInvestDetail: {
    keyWord: "Buscar por palabra clave...",
    by: "Filtrar por",
    businessLine: "Línea de negocio",
    investmentState: "Estado de la transacción",
    campaignState: "Estado de la campaña",
    businessLineOp: {
      todos: "Todos",
      deuda: "Deuda",
      acciones: "Acciones",
      nota: "Nota Convertible"
    },
    investmentStateOp: {
      active: "Aprobada",
      canceled: "Cancelada",
      exchange: "En canje"
    },
    campaignStateOp: {
      active: "Abierta",
      close: "Cerrada",
      awarded: "Adjudicado"
    },
    Restore: "Restablecer",
    Filter: "Filtrar",
    download: "Estamos preparando tu archivo."
  }
};
